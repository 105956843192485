define("pilas-engine/components/pilas-reiniciador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activo: true,
    bus: Ember.inject.service(),
    tagName: "",

    didInsertElement() {
      this.bus.on("recargarCanvasDePilas", this, "recargarCanvasDePilas");
    },

    didDestroyElement() {
      this.bus.off("recargarCanvasDePilas", this, "recargarCanvasDePilas");
    },

    recargarCanvasDePilas() {
      this.send("reiniciar");
    },

    actions: {
      reiniciar() {
        this.set("activo", false);
        Ember.run.later(this, () => {
          this.set("activo", true);
        }, 500);
      }

    }
  });

  _exports.default = _default;
});