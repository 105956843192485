define("pilas-engine/router", ["exports", "pilas-engine/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Ember.Router.reopen({
    estadisticas: Ember.inject.service(),
    rutaAnterior: "",

    init() {
      this._super(...arguments);

      this.on("routeDidChange", transition => {
        this.estadisticas.notificar_transicion(transition.to.name);
      });
    }

  });
  Router.map(function () {
    this.route("acercade");
    this.route("pilas");
    this.route("manual");
    this.route("api");
    this.route("experimentos");
    this.route("pruebas");
    this.route("editor");
    this.route("ejemplos", function () {
      this.route("ver", {
        path: ":nombre"
      });
    });
    this.route("app", function () {
      // abre el proyecto desde electron
      this.route("abrir_proyecto", {
        path: "abrir_proyecto/:ruta"
      }); // abre el proyecto desde el navegador

      this.route("abrir_proyecto_serializado", {
        path: "abrir_proyecto_serializado/:hash"
      });
      this.route('editor', function () {
        this.route('abandonar-proyecto');
      });
      this.route('crear_proyecto');
    });
    this.route("proyecto", {
      path: "proyecto/:hash"
    });
    this.route('iniciar-proyecto');
    this.route('explorar', function () {});
    this.route('mis-juegos', function () {});
  });
  var _default = Router;
  _exports.default = _default;
});