define("pilas-engine/templates/mis-juegos/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SqBGLG0I",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"outlet\"],false],[0,\"\\n\\n\"],[1,[29,\"pilas-explorador-de-proyectos\",null,[[\"pagina\",\"soloMisJuegos\"],[[25,[\"pagina\"]],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/mis-juegos/index.hbs"
    }
  });

  _exports.default = _default;
});