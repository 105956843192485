define("pilas-engine/templates/components/ui/importar-imagen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T/36/pFl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"],[1,[29,\"pilas-boton\",null,[[\"accion\",\"texto\"],[[29,\"action\",[[24,0,[]],\"seleccionar_imagen\"],null],[29,\"t\",[\"import.image\"],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"dn\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"multiple\",\"true\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"upload\"],null]],[11,\"accept\",\"image/png\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/ui/importar-imagen.hbs"
    }
  });

  _exports.default = _default;
});