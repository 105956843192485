define("pilas-engine/components/pilas-boton-renombrar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mostrar: false,
    proyecto: Ember.inject.service(),
    tagName: "span",

    hacer_foco() {
      this.element.getElementsByTagName("input")[0].focus();
      this.element.getElementsByTagName("input")[0].select();
    },

    invalido_por_duplicado: Ember.computed("nombreSugerido", function () {
      return this.nombres_no_permitidos.indexOf(this.nombreSugerido) > -1;
    }),
    invalido_por_vacio: Ember.computed("nombreSugerido", function () {
      return this.nombreSugerido.length < 1;
    }),
    invalido_por_caracteres: Ember.computed("nombreSugerido", function () {
      return !/^[a-zA-Z_][0-9a-zA-Z_]+$/.test(this.nombreSugerido);
    }),
    invalido: Ember.computed("invalido_por_caracteres", "invalido_por_vacio", "invalido_por_duplicado", function () {
      return this.invalido_por_caracteres || this.invalido_por_vacio || this.invalido_por_duplicado;
    }),

    obtener_nombres_no_permitidos() {
      let actores = this.proyecto.obtener_nombres_de_actores();
      let escenas = this.proyecto.obtener_nombres_de_todas_las_escenas();
      let nombres = [...actores, ...escenas, "proyecto", "Proyecto"];
      nombres.removeObject(this.nombre);
      return nombres;
    },

    actions: {
      cambiarNombre() {
        this.set("mostrar", true);
        this.set("nombreSugerido", this.nombre);
        this.set("nombres_no_permitidos", this.obtener_nombres_no_permitidos());
        Ember.run.later(this, this.hacer_foco, 1);
      },

      ocultar() {
        this.set("mostrar", false);
      },

      confirmar() {
        if (this.invalido) {
          return;
        }

        if (this.es_actor) {
          this.proyecto.renombrar_actor(this.nombre, this.nombreSugerido);
          this.send("ocultar");
          this.cuando_cambia(this.nombre);
        } else {
          this.proyecto.renombrar_escena(this.nombre, this.nombreSugerido);
          this.send("ocultar");
          this.cuando_cambia(this.nombre);
        }
      }

    }
  });

  _exports.default = _default;
});