define("pilas-engine/templates/components/pilas-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "367yHRKt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"tc mt4 lato gray\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dib w1\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"enDesarrollo\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"t\",[\"versions.development\"],null],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"f7 link gray\"],[11,\"href\",\"https://github.com/pilas-engine/pilas-engine/commits/master\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[1,[23,\"version\"],false],[0,\" (\"],[1,[29,\"t\",[\"versions.more.info\"],null],false],[0,\")\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,\"version_simplificada\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"w1 dib pt1\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"consultando\"]]],null,{\"statements\":[[4,\"pilas-tooltip\",null,[[\"class\",\"texto\"],[\"w4\",[29,\"t\",[\"versions.searching\"],null]]],{\"statements\":[[0,\"        \"],[1,[23,\"pilas-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"actualizada\"]]],null,{\"statements\":[[4,\"pilas-tooltip\",null,[[\"class\",\"texto\"],[\"w4\",[29,\"t\",[\"versions.updated\"],null]]],{\"statements\":[[0,\"          \"],[1,[29,\"pilas-mini-icono\",null,[[\"icono\"],[\"actualizada\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"pilas-tooltip\",null,[[\"class\",\"texto\"],[\"right-1 w4\",[29,\"t\",[\"versions.new\"],null]]],{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"red dib\"],[9],[0,\"\\n            \"],[1,[29,\"pilas-mini-icono\",null,[[\"icono\"],[\"desactualizada\"]]],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-version.hbs"
    }
  });

  _exports.default = _default;
});