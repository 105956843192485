define("pilas-engine/fixtures/animaciones-iniciales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    nombre: "explosion",
    cuadros: [{
      nombre: "imagenes:explosion/explosion_001",
      sprite: "imagenes-explosion-explosion_001"
    }, {
      nombre: "imagenes:explosion/explosion_002",
      sprite: "imagenes-explosion-explosion_002"
    }, {
      nombre: "imagenes:explosion/explosion_003",
      sprite: "imagenes-explosion-explosion_003"
    }, {
      nombre: "imagenes:explosion/explosion_004",
      sprite: "imagenes-explosion-explosion_004"
    }, {
      nombre: "imagenes:explosion/explosion_005",
      sprite: "imagenes-explosion-explosion_005"
    }, {
      nombre: "imagenes:explosion/explosion_006",
      sprite: "imagenes-explosion-explosion_006"
    }, {
      nombre: "imagenes:explosion/explosion_007",
      sprite: "imagenes-explosion-explosion_007"
    }, {
      nombre: "imagenes:explosion/explosion_008",
      sprite: "imagenes-explosion-explosion_008"
    }, {
      nombre: "imagenes:explosion/explosion_009",
      sprite: "imagenes-explosion-explosion_009"
    }, {
      nombre: "imagenes:explosion/explosion_010",
      sprite: "imagenes-explosion-explosion_010"
    }, {
      nombre: "imagenes:explosion/explosion_011",
      sprite: "imagenes-explosion-explosion_011"
    }, {
      nombre: "imagenes:explosion/explosion_012",
      sprite: "imagenes-explosion-explosion_012"
    }, {
      nombre: "imagenes:explosion/explosion_013",
      sprite: "imagenes-explosion-explosion_013"
    }, {
      nombre: "imagenes:explosion/explosion_014",
      sprite: "imagenes-explosion-explosion_014"
    }, {
      nombre: "imagenes:explosion/explosion_015",
      sprite: "imagenes-explosion-explosion_015"
    }],
    velocidad: "30"
  }, {
    nombre: "mini_explosion",
    cuadros: [{
      nombre: "imagenes:mini-explosion/explosion_0",
      sprite: "imagenes-mini-explosion-explosion_0"
    }, {
      nombre: "imagenes:mini-explosion/explosion_1",
      sprite: "imagenes-mini-explosion-explosion_1"
    }, {
      nombre: "imagenes:mini-explosion/explosion_2",
      sprite: "imagenes-mini-explosion-explosion_2"
    }, {
      nombre: "imagenes:mini-explosion/explosion_3",
      sprite: "imagenes-mini-explosion-explosion_3"
    }, {
      nombre: "imagenes:mini-explosion/explosion_4",
      sprite: "imagenes-mini-explosion-explosion_4"
    }, {
      nombre: "imagenes:mini-explosion/explosion_5",
      sprite: "imagenes-mini-explosion-explosion_5"
    }, {
      nombre: "imagenes:mini-explosion/explosion_6",
      sprite: "imagenes-mini-explosion-explosion_6"
    }],
    velocidad: "20"
  }, {
    nombre: "golpe",
    cuadros: [{
      nombre: "imagenes:efectos/golpe-1",
      sprite: "imagenes-efectos-golpe-1"
    }, {
      nombre: "imagenes:efectos/golpe-2",
      sprite: "imagenes-efectos-golpe-2"
    }, {
      nombre: "imagenes:efectos/golpe-3",
      sprite: "imagenes-efectos-golpe-3"
    }, {
      nombre: "imagenes:efectos/golpe-4",
      sprite: "imagenes-efectos-golpe-4"
    }, {
      nombre: "imagenes:efectos/golpe-5",
      sprite: "imagenes-efectos-golpe-5"
    }, {
      nombre: "imagenes:efectos/golpe-6",
      sprite: "imagenes-efectos-golpe-6"
    }],
    velocidad: "15"
  }, {
    nombre: "chispa",
    cuadros: [{
      nombre: "imagenes:efectos/chispa-1",
      sprite: "imagenes-efectos-chispa-1"
    }, {
      nombre: "imagenes:efectos/chispa-2",
      sprite: "imagenes-efectos-chispa-2"
    }, {
      nombre: "imagenes:efectos/chispa-3",
      sprite: "imagenes-efectos-chispa-3"
    }, {
      nombre: "imagenes:efectos/chispa-4",
      sprite: "imagenes-efectos-chispa-4"
    }, {
      nombre: "imagenes:efectos/chispa-5",
      sprite: "imagenes-efectos-chispa-5"
    }],
    velocidad: "20"
  }, {
    nombre: "humo",
    cuadros: [{
      nombre: "imagenes:efectos/humo-01",
      sprite: "imagenes-efectos-humo-01"
    }, {
      nombre: "imagenes:efectos/humo-02",
      sprite: "imagenes-efectos-humo-02"
    }, {
      nombre: "imagenes:efectos/humo-03",
      sprite: "imagenes-efectos-humo-03"
    }, {
      nombre: "imagenes:efectos/humo-04",
      sprite: "imagenes-efectos-humo-04"
    }, {
      nombre: "imagenes:efectos/humo-05",
      sprite: "imagenes-efectos-humo-05"
    }, {
      nombre: "imagenes:efectos/humo-06",
      sprite: "imagenes-efectos-humo-06"
    }, {
      nombre: "imagenes:efectos/humo-07",
      sprite: "imagenes-efectos-humo-07"
    }, {
      nombre: "imagenes:efectos/humo-08",
      sprite: "imagenes-efectos-humo-08"
    }, {
      nombre: "imagenes:efectos/humo-09",
      sprite: "imagenes-efectos-humo-09"
    }, {
      nombre: "imagenes:efectos/humo-10",
      sprite: "imagenes-efectos-humo-10"
    }],
    velocidad: "15"
  }, {
    nombre: "hombre_pixel_camina",
    cuadros: [{
      nombre: "imagenes:pixelados/pixel_player_1",
      sprite: "imagenes-pixelados-pixel_player_1"
    }, {
      nombre: "imagenes:pixelados/pixel_player_2",
      sprite: "imagenes-pixelados-pixel_player_2"
    }],
    velocidad: "15"
  }, {
    nombre: "hombre_pixel_parado",
    cuadros: [{
      nombre: "imagenes:pixelados/pixel_player_0",
      sprite: "imagenes-pixelados-pixel_player_0"
    }],
    velocidad: 10
  }, {
    nombre: "fantasma_azul_camina",
    cuadros: [{
      nombre: "imagenes:pacman/fantasma_azul_0",
      sprite: "imagenes-pacman-fantasma_azul_0"
    }, {
      nombre: "imagenes:pacman/fantasma_azul_1",
      sprite: "imagenes-pacman-fantasma_azul_1"
    }],
    velocidad: "10"
  }, {
    nombre: "fantasma_camina",
    cuadros: [{
      nombre: "imagenes:pacman/fantasma_6",
      sprite: "imagenes-pacman-fantasma_6"
    }, {
      nombre: "imagenes:pacman/fantasma_7",
      sprite: "imagenes-pacman-fantasma_7"
    }],
    velocidad: "10"
  }, {
    nombre: "moneda",
    cuadros: [{
      nombre: "imagenes:monedas/moneda_0",
      sprite: "imagenes-monedas-moneda_0"
    }, {
      nombre: "imagenes:monedas/moneda_1",
      sprite: "imagenes-monedas-moneda_1"
    }, {
      nombre: "imagenes:monedas/moneda_2",
      sprite: "imagenes-monedas-moneda_2"
    }, {
      nombre: "imagenes:monedas/moneda_3",
      sprite: "imagenes-monedas-moneda_3"
    }, {
      nombre: "imagenes:monedas/moneda_4",
      sprite: "imagenes-monedas-moneda_4"
    }, {
      nombre: "imagenes:monedas/moneda_5",
      sprite: "imagenes-monedas-moneda_5"
    }, {
      nombre: "imagenes:monedas/moneda_6",
      sprite: "imagenes-monedas-moneda_6"
    }, {
      nombre: "imagenes:monedas/moneda_7",
      sprite: "imagenes-monedas-moneda_7"
    }],
    velocidad: "30"
  }, {
    nombre: "pacman_come",
    cuadros: [{
      nombre: "imagenes:pacman/pacman_4",
      sprite: "imagenes-pacman-pacman_4"
    }, {
      nombre: "imagenes:pacman/pacman_5",
      sprite: "imagenes-pacman-pacman_5"
    }, {
      nombre: "imagenes:pacman/pacman_6",
      sprite: "imagenes-pacman-pacman_6"
    }],
    velocidad: "20"
  }, {
    nombre: "pacman_espera",
    cuadros: [{
      nombre: "imagenes:pacman/pacman_4",
      sprite: "imagenes-pacman-pacman_4"
    }],
    velocidad: 10
  }, {
    nombre: "nave_girando_a_la_derecha",
    cuadros: [{
      nombre: "imagenes:nave/nave_derecha_1",
      sprite: "imagenes-nave-nave_derecha_1"
    }, {
      nombre: "imagenes:nave/nave_derecha_2",
      sprite: "imagenes-nave-nave_derecha_2"
    }],
    velocidad: "20"
  }, {
    nombre: "nave_girando_a_la_izquierda",
    cuadros: [{
      nombre: "imagenes:nave/nave_izquierda_1",
      sprite: "imagenes-nave-nave_izquierda_1"
    }, {
      nombre: "imagenes:nave/nave_izquierda_2",
      sprite: "imagenes-nave-nave_izquierda_2"
    }],
    velocidad: "20"
  }, {
    nombre: "nave_avanzando",
    cuadros: [{
      nombre: "imagenes:nave/nave_avanza_1",
      sprite: "imagenes-nave-nave_avanza_1"
    }, {
      nombre: "imagenes:nave/nave_avanza_2",
      sprite: "imagenes-nave-nave_avanza_2"
    }],
    velocidad: "20"
  }, {
    nombre: "nave_en_reposo",
    cuadros: [{
      nombre: "imagenes:nave/nave_reposo",
      sprite: "imagenes-nave-nave_reposo"
    }],
    velocidad: 10
  }, {
    nombre: "gallina_vuela",
    cuadros: [{
      nombre: "imagenes:gallina/gallina_vuela_1",
      sprite: "imagenes-gallina-gallina_vuela_1"
    }, {
      nombre: "imagenes:gallina/gallina_vuela_2",
      sprite: "imagenes-gallina-gallina_vuela_2"
    }, {
      nombre: "imagenes:gallina/gallina_vuela_3",
      sprite: "imagenes-gallina-gallina_vuela_3"
    }, {
      nombre: "imagenes:gallina/gallina_vuela_2",
      sprite: "imagenes-gallina-gallina_vuela_2"
    }],
    velocidad: "20"
  }, {
    nombre: "nave_avanza",
    cuadros: [{
      nombre: "imagenes:nave/nave_avanza_1",
      sprite: "imagenes-nave-nave_avanza_1"
    }, {
      nombre: "imagenes:nave/nave_avanza_2",
      sprite: "imagenes-nave-nave_avanza_2"
    }],
    velocidad: 10
  }, {
    nombre: "conejo_salta",
    cuadros: [{
      nombre: "imagenes:conejo/conejo_salta",
      sprite: "imagenes-conejo-conejo_salta"
    }],
    velocidad: 10
  }, {
    nombre: "conejo_parado",
    cuadros: [{
      nombre: "imagenes:conejo/conejo_parado1",
      sprite: "imagenes-conejo-conejo_parado1"
    }, {
      nombre: "imagenes:conejo/conejo_parado2",
      sprite: "imagenes-conejo-conejo_parado2"
    }],
    velocidad: "2"
  }, {
    nombre: "conejo_camina",
    velocidad: 15,
    cuadros: [{
      nombre: "imagenes:conejo/conejo_camina1",
      sprite: "imagenes-conejo-conejo_camina1"
    }, {
      nombre: "imagenes:conejo/conejo_camina2",
      sprite: "imagenes-conejo-conejo_camina2"
    }]
  }, {
    nombre: "bmo_camina",
    velocidad: 15,
    cuadros: [{
      nombre: "imagenes:bmo/bmo_camina_1",
      sprite: "imagenes-bmo-bmo_camina_1"
    }, {
      nombre: "imagenes:bmo/bmo_camina_2",
      sprite: "imagenes-bmo-bmo_camina_2"
    }]
  }, {
    nombre: "bmo_escala",
    cuadros: [{
      nombre: "imagenes:bmo/bmo_escala_1",
      sprite: "imagenes-bmo-bmo_escala_1"
    }, {
      nombre: "imagenes:bmo/bmo_escala_2",
      sprite: "imagenes-bmo-bmo_escala_2"
    }],
    velocidad: 10
  }, {
    nombre: "bmo_salta",
    cuadros: [{
      nombre: "imagenes:bmo/bmo_salta",
      sprite: "imagenes-bmo-bmo_salta"
    }],
    velocidad: 10
  }, {
    nombre: "bmo_parado",
    cuadros: [{
      nombre: "imagenes:bmo/bmo_parado",
      sprite: "imagenes-bmo-bmo_parado"
    }],
    velocidad: 10
  }];
  _exports.default = _default;
});