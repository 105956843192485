define("pilas-engine/fixtures/sonidos-iniciales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    nombre: "laser",
    ruta: "sonidos/laser.mp3"
  }, {
    nombre: "explosion",
    ruta: "sonidos/explosion.mp3"
  }, {
    nombre: "gallina",
    ruta: "sonidos/gallina.mp3"
  }, {
    nombre: "moneda",
    ruta: "sonidos/moneda.mp3"
  }, {
    nombre: "salto-corto",
    ruta: "sonidos/salto-corto.mp3"
  }, {
    nombre: "salto-largo",
    ruta: "sonidos/salto-largo.mp3"
  }, {
    nombre: "seleccion-aguda",
    ruta: "sonidos/seleccion-aguda.mp3"
  }, {
    nombre: "seleccion-grave",
    ruta: "sonidos/seleccion-grave.mp3"
  }, {
    nombre: "comer",
    ruta: "sonidos/comer.mp3"
  }];
  _exports.default = _default;
});