define("pilas-engine/components/pilas-explorador-de-proyectos", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tc', 'pb3'],
    api: Ember.inject.service(),
    pagina: 1,

    didInsertElement() {
      if (this.pagina < 0) {
        this.set("pagina", 1);
      }

      this.proyectos.perform(this.etiqueta, this.pagina);
    },

    proyectos: (0, _emberConcurrency.task)(function* (etiqueta, pagina, mostrarRecientesAgrupados) {
      yield (0, _emberConcurrency.timeout)(1000);
      return yield this.api.obtener_lista_de_proyectos(pagina, etiqueta, this.soloMisJuegos, mostrarRecientesAgrupados);
    })
  });

  _exports.default = _default;
});