define("pilas-engine/components/pilas-lista-de-ejemplos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tc'],
    ejemplos: Ember.inject.service(),
    cantidad_de_ejemplos: 36,
    filtro_diferido: "",

    didInsertElement() {
      this.ejemplos.obtener();
      document.querySelector("#filtro-de-ejemplos").focus();
    },

    // se hace un observer para que el filtro tarde en aplicarse.
    observer_de_filtro: Ember.observer("filtro", function () {
      Ember.run.debounce(this, () => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.set("filtro_diferido", this.filtro);
      }, 400);
    })
  });

  _exports.default = _default;
});