define("pilas-engine/services/estadisticas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    iniciar() {},

    notificar_transicion()
    /*ruta*/
    {
      /*
      if (ga) {
        later(() => {
          let params = {
            page: ruta,
            title: ruta
          };
           ga("send", "pageview", params);
        });
      }
      */
    }

  });

  _exports.default = _default;
});