define("pilas-engine/templates/components/pilas-boton-miniatura-pulsable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MSJx4vkr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"desactivado\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"class\"],\" o-50\"]]],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"class\"],\" pulsable\"]]],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"pulsar\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-miniatura-pulsable.hbs"
    }
  });

  _exports.default = _default;
});