define("pilas-engine/templates/components/pilas-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EsDQQ0GI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[7,\"iframe\"],[12,\"src\",[23,\"url\"]],[11,\"class\",\"bn\"],[11,\"sandbox\",\"allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts\"],[11,\"width\",\"100%\"],[11,\"height\",\"100%\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-api.hbs"
    }
  });

  _exports.default = _default;
});