define("pilas-engine/templates/components/pilas-input-nombre-validado-de-sensor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lwbEJhTL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n    \"],[1,[29,\"input\",null,[[\"enter\",\"value\",\"type\",\"class\"],[[29,\"action\",[[24,0,[]],\"confirmar\"],null],[25,[\"nombreSugerido\"]],\"text\",\"input w-100 v-top pa1 br2\"]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"flex pt2\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex1\"],[9],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n        \"],[7,\"button\"],[12,\"class\",[30,[\"boton \",[29,\"if\",[[25,[\"invalido\"]],\"o-50\"],null]]]],[12,\"disabled\",[23,\"invalido\"]],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"ok\"],null],false],[0,\"\\n        \"],[3,\"action\",[[24,0,[]],\"confirmar\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"tc f7 lh-copy\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"invalido_por_duplicado\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"pt2\"],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"validations.name.exists\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"invalido_por_vacio\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"pt2\"],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"validations.not.empty\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"invalido_por_caracteres\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"pt2\"],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"validations.invalid\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-input-nombre-validado-de-sensor.hbs"
    }
  });

  _exports.default = _default;
});