define("pilas-engine/templates/components/pilas-propiedad/habilidades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ndrnDCOq",
    "block": "{\"symbols\":[\"habilidad\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pv1\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"pb1\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"dib texto\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[[25,[\"etiqueta\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"v-top pointer pt1 w240\"],[9],[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"options\",\"selected\",\"placeholder\",\"onchange\"],[[25,[\"habilidades\"]],[29,\"get\",[[25,[\"objeto\"]],[25,[\"propiedad\",\"propiedad\"]]],null],\"Жодної\",[29,\"action\",[[24,0,[]],\"cuando_selecciona\"],null]]],{\"statements\":[[0,\"      \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-propiedad/habilidades.hbs"
    }
  });

  _exports.default = _default;
});