define("pilas-engine/components/pilas-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    s: 14,
    size: Ember.computed('s', function () {
      return `${this.s}px`;
    })
  });

  _exports.default = _default;
});