define("pilas-engine/components/pantalla/iniciar-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tieneProyectoAnterior: false,
    capturaDelProyectoAnterior: null,

    init() {
      this._super(...arguments);

      let captura = localStorage.getItem("pilas:captura_de_pantalla");
      let proyectoAnterior = localStorage.getItem("pilas:proyecto_serializado");

      if (captura && proyectoAnterior) {
        this.set("tieneProyectoAnterior", true);
        this.set("capturaDelProyectoAnterior", captura);
      } else {
        this.set("tieneProyectoAnterior", false);
      }
    }

  });

  _exports.default = _default;
});