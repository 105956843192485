define("pilas-engine/controllers/actores", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const queryParams = new _emberParachute.default({
    actor_seleccionado: {
      defaultValue: null,
      refresh: true,
      replace: true
    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    actores: Ember.inject.service(),
    actor: Ember.computed("actor_seleccionado", function () {
      let actor_seleccionado = this.actor_seleccionado;

      if (actor_seleccionado) {
        return this.get("actores.lista_de_actores").findBy("nombre", actor_seleccionado);
      } else {
        return null;
      }
    }),

    reset(_, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
      }
    },

    actions: {
      al_seleccionar_un_actor(actor) {
        this.set("actor_seleccionado", actor.nombre);
      }

    }
  });

  _exports.default = _default;
});