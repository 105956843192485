define("pilas-engine/fixtures/proyecto-inicial-desde-cero", ["exports", "pilas-engine/fixtures/animaciones-iniciales"], function (_exports, _animacionesIniciales) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    titulo: "Proyecto",
    ancho: 500,
    alto: 500,
    tamaño: "500x500",
    nombre_de_la_escena_inicial: "escena1",
    imagenes: [],
    animaciones: _animacionesIniciales.default,
    codigos: {
      escenas: [{
        nombre: "escena1",
        codigo: "class escena1 extends Escena {\n    iniciar() {\n\n    }\n\n    actualizar() {\n\n    }\n}"
      }],
      actores: []
    },
    escenas: [{
      nombre: "escena1",
      id: 1,
      ancho: 1000,
      alto: 1000,
      camara_x: 0,
      camara_y: 0,
      gravedad_x: 0,
      gravedad_y: 1,
      fondo: "decoracion:fondos/fondo-plano",
      actores: []
    }]
  };
  _exports.default = _default;
});