define("pilas-engine/components/pilas-manual", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["flex1"],
    seccion: "index.html",
    currentURL: "",
    iframeURL: "",
    prefijo: "manual/",
    observarURL: (0, _emberConcurrency.task)(function* () {
      while (true) {
        let element = this.element.querySelector("iframe");
        let url = element.contentDocument.location.pathname;

        if (url === "blank") {
          yield (0, _emberConcurrency.timeout)(2000);
          continue;
        }

        url = url.split("manual/")[1];
        url = url.replace(this.prefijo, "").replace(/\//g, "__");

        if (this.currentURL != url && url.includes(".html")) {
          this.set("currentURL", url);

          if (this.cuandoCambiaURL) {
            this.cuandoCambiaURL(url.replace(".html", ""));
          }
        }

        yield (0, _emberConcurrency.timeout)(2000);
      }
    }),

    didInsertElement() {
      this.observarURL.perform({});
      this.set("iframeURL", this.prefijo + this.seccion.replace(/__/g, "/") + ".html");
    }

  });

  _exports.default = _default;
});