define("pilas-engine/routes/explorar/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      pagina: {
        replace: true
      },
      etiqueta: {
        replace: true
      }
    }
  });

  _exports.default = _default;
});