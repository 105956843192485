define("pilas-engine/templates/components/pilas-reiniciador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IgFYvE0W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"activo\"]]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex1 textp\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"dn\"],[11,\"name\",\"button\"],[11,\"type\",\"button\"],[9],[0,\"Перезапустити\"],[3,\"action\",[[24,0,[]],\"reiniciar\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-reiniciador.hbs"
    }
  });

  _exports.default = _default;
});