define("pilas-engine/components/item-de-la-lista-de-ejemplos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    clases: Ember.computed("filtro", "ejemplo.nombre", function () {
      if (this.filtro && !this.ejemplo.nombre.includes(this.filtro)) {
        return "dn link relative pointer v-top mb2 mr2 ejemplo";
      } else {
        return "dib link relative pointer v-top mb2 mr2 ejemplo";
      }
    })
  });

  _exports.default = _default;
});