define("pilas-engine/components/pilas-skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    style: Ember.computed("ancho", "alto", function () {
      let {
        ancho,
        alto
      } = this;

      if (!isNaN(ancho)) {
        ancho += "px";
      }

      if (!isNaN(alto)) {
        alto += "px";
      }

      return Ember.String.htmlSafe(`width: ${ancho}; height: ${alto};`);
    })
  });

  _exports.default = _default;
});