define("pilas-engine/templates/components/pilas-propiedad/numero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7mm0K9Zo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-50\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"texto pv1 lh-title unselectable\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"dib data-etiqueta cursor-resize-on-hover bb-dashed-on-hover\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[[25,[\"etiqueta\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-50\"],[9],[0,\"\\n    \"],[1,[29,\"input-numero\",null,[[\"min\",\"max\",\"valor\",\"cuandoCambia\"],[[25,[\"propiedad\",\"min\"]],[25,[\"propiedad\",\"max\"]],[29,\"round\",[[29,\"get\",[[25,[\"objeto\"]],[25,[\"propiedad\",\"propiedad\"]]],null]],null],[29,\"action\",[[24,0,[]],\"modificar_desde_input\",[25,[\"objeto\"]],[25,[\"propiedad\",\"propiedad\"]]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-propiedad/numero.hbs"
    }
  });

  _exports.default = _default;
});