define("pilas-engine/templates/components/pilas-loader-del-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LZ9z1epd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"mostrarLoader\"]],\"absolute flex items-center justify-center\",\"dn\"],null],\" absolute--fill bg-loader-del-panel-editor z-top\"]]],[9],[0,\"\\n  \"],[1,[29,\"pilas-spinner\",null,[[\"s\"],[32]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-loader-del-editor.hbs"
    }
  });

  _exports.default = _default;
});