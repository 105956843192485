define("pilas-engine/utils/recetas/actor/cuando-colisiona-emitir-mensaje", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorCuandoColisionaEmitirMensaje;

  function recetasActorCuandoColisionaEmitirMensaje() {
    return {
      titulo: "Коли актор зіткнеться, повідомити та видалити іншого, якщо це ворог",
      icono: "receta_colision",
      para: "actor",
      etiquetas: ["colisión", "toca", "golpea"],
      codigo: `
            // Викликається, якщо двоє акторів стикаються динамічними формами
            // або один із динамічною формою та один із не динамічною формою.
            cuando_comienza_una_colision(otro_actor: Actor) {

              this.decir("Я зіткнувся з: " + otro_actor.etiqueta);

              if (otro_actor.etiqueta == "enemigo") {
                this.eliminar();
              }
            }
        `
    };
  }
});