define("pilas-engine/templates/components/pilas-lista-de-ejemplos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hYcHBFT0",
    "block": "{\"symbols\":[\"ejemplo\",\"&default\"],\"statements\":[[15,2],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"tr bg-light-gray pa2 mb3\"],[9],[0,\"\\n  \"],[1,[29,\"input\",null,[[\"id\",\"autofocus\",\"placeholder\",\"value\",\"class\",\"autocomplete\"],[\"filtro-de-ejemplos\",true,\"Фільтр\",[25,[\"filtro\"]],\"pa2 input\",\"off\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"ejemplos\",\"tarea\",\"last\",\"isRunning\"]]],null,{\"statements\":[[4,\"each\",[[29,\"range\",[0,[25,[\"cantidad_de_ejemplos\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"dib v-top mb2 mr1\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"relative bg-light-gray\"],[11,\"style\",\"width: 180px; height: 140px;\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"bg-gray pa1 white tc lato f7\"],[9],[0,\"\\n         \\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[25,[\"ejemplos\",\"tarea\",\"last\",\"value\",\"ejemplos\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[29,\"item-de-la-lista-de-ejemplos\",null,[[\"filtro\",\"ejemplo\"],[[25,[\"filtro_diferido\"]],[24,1,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-lista-de-ejemplos.hbs"
    }
  });

  _exports.default = _default;
});