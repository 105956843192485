define("pilas-engine/templates/components/pilas-boton-animaciones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hvpn8/6B",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[1,[29,\"pilas-boton\",null,[[\"responsive\",\"icono\",\"desactivado\",\"accion\",\"texto\"],[true,\"animaciones\",[25,[\"desactivado\"]],[29,\"action\",[[24,0,[]],\"abrirModal\"],null],[29,\"t\",[\"animations.title\"],null]]]],false],[0,\"\\n\\n\"],[4,\"pilas-modal\",null,[[\"alCerrar\",\"visible\",\"id\",\"titulo\"],[[29,\"action\",[[24,0,[]],\"ocultar\"],null],[25,[\"modalVisible\"]],\"dialogoAnimacion\",[29,\"t\",[\"animations.title\"],null]]],{\"statements\":[[0,\"  \"],[1,[29,\"pilas-selector-de-animaciones\",null,[[\"proyecto\",\"pixelart\"],[[25,[\"proyecto\"]],[25,[\"pixelart\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-animaciones.hbs"
    }
  });

  _exports.default = _default;
});