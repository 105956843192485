define("pilas-engine/templates/components/pilas-propiedad/interruptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gBdXbukG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pv1 flex\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-50\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"texto truncate\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[[25,[\"etiqueta\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-50 pb1\"],[9],[0,\"\\n    \"],[1,[29,\"pilas-interruptor\",null,[[\"variable\",\"padding\",\"cuandoCambia\"],[[29,\"get\",[[25,[\"objeto\"]],[25,[\"propiedad\",\"propiedad\"]]],null],\"pa0\",[29,\"action\",[[24,0,[]],\"cuando_cambia\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-propiedad/interruptor.hbs"
    }
  });

  _exports.default = _default;
});