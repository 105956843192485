define("pilas-engine/templates/components/abrir-selector-con-teclado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qFOqLi8n",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/abrir-selector-con-teclado.hbs"
    }
  });

  _exports.default = _default;
});