define("pilas-engine/templates/components/pilas-pruebas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iSQaYoy2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"vh-100 flex\"],[9],[0,\"\\n\"],[4,\"pilas-reiniciador\",null,null,{\"statements\":[[0,\"    \"],[1,[29,\"pilas-canvas\",null,[[\"nombre_del_contexto\",\"ancho\",\"alto\",\"proyecto\",\"modoZoom\",\"cuando_termina_de_cargar\"],[\"pruebas\",[25,[\"proyecto\",\"ancho\"]],[25,[\"proyecto\",\"alto\"]],[25,[\"proyecto\"]],2,[29,\"action\",[[24,0,[]],\"cuando_termina_de_cargar\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-pruebas.hbs"
    }
  });

  _exports.default = _default;
});