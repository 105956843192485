define("pilas-engine/templates/components/pilas-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TpBHfRx7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dib\"],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"version\",\"1.1\"],[11,\"id\",\"loader-1\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"x\",\"0px\"],[11,\"y\",\"0px\"],[12,\"width\",[23,\"size\"]],[12,\"height\",[23,\"size\"]],[11,\"viewBox\",\"0 0 40 40\"],[11,\"enable-background\",\"new 0 0 40 40\"],[11,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[9],[0,\"\\n    \"],[7,\"path\"],[11,\"opacity\",\"0.5\"],[11,\"class\",\"spinner-color-de-fondo\"],[11,\"d\",\"M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946\\n    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634\\n    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z\"],[9],[10],[0,\"\\n    \"],[7,\"path\"],[11,\"class\",\"spinner-color\"],[11,\"d\",\"M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0\\n    C22.32,8.481,24.301,9.057,26.013,10.047z\"],[9],[0,\"\\n      \"],[7,\"animateTransform\"],[11,\"attributeType\",\"xml\"],[11,\"attributeName\",\"transform\"],[11,\"from\",\"0 20 20\"],[11,\"to\",\"360 20 20\"],[11,\"dur\",\"0.5s\"],[11,\"repeatCount\",\"indefinite\"],[11,\"type\",\"rotate\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-spinner.hbs"
    }
  });

  _exports.default = _default;
});