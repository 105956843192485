define("pilas-engine/utils/recetas/escena/crear-actores-cada-determinado-tiempo-en-segundos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasEscenaCrearActoresCadaDeterminadoTiempoEnSegundos;

  function recetasEscenaCrearActoresCadaDeterminadoTiempoEnSegundos() {
    return {
      titulo: "Створювати акторів через зазначений час в секундах",
      icono: "receta_tiempo",
      para: "escena",
      etiquetas: ["tiempo", "crear", "contar", "temporizado", "paso", "segundos"],
      codigo: `
          cada_segundo(segundos) {
              this.pilas.observar("Segundos transcurridos", segundos);

              // Створювати актора Коробка кожні 5 секунд.
              if (this.pilas.es_multiplo(segundos, 5)) {
                  this.pilas.actores.caja();
              }

              // Створювати актора М'яч кожні 3 секунди.
              if (this.pilas.es_multiplo(segundos, 3)) {
                  this.pilas.actores.pelota()
              }
          }
      `
    };
  }
});