define("pilas-engine/routes/proyecto", ["exports", "pilas-engine/utils/string-a-json", "pilas-engine/utils/convertir-proyecto-en-objeto-ember"], function (_exports, _stringAJson, _convertirProyectoEnObjetoEmber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    api: Ember.inject.service(),
    migraciones: Ember.inject.service(),

    model(params) {
      return this.api.obtener_proyecto(params.hash).then(data => {
        return {
          hash: params.hash,
          proyecto: data
        };
      });
    },

    afterModel(model) {
      model.modoZoom = 2;
      let proyecto = (0, _stringAJson.default)(model.proyecto.serializado);
      model.ver_codigo = model.proyecto.ver_codigo;
      model.proyecto = this.migraciones.migrar((0, _convertirProyectoEnObjetoEmber.default)(proyecto.proyecto));
    }

  });

  _exports.default = _default;
});