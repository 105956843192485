define("pilas-engine/templates/components/pilas-ejemplo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wgL6xNQQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"recursos\",\"data\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"unless\",[[25,[\"cambiando\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"pilas-canvas\",null,[[\"nombre_del_contexto\",\"ancho\",\"alto\",\"modoZoom\",\"proyecto\",\"pixelart\"],[\"ejemplo\",[25,[\"proyecto\",\"ancho\"]],[25,[\"proyecto\",\"alto\"]],[25,[\"modoZoom\"]],[25,[\"proyecto\"]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-ejemplo.hbs"
    }
  });

  _exports.default = _default;
});