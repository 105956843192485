define("pilas-engine/utils/aplicar-nombre", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = aplicar_nombre;

  function aplicar_nombre(nombre, codigo) {
    let resultado = codigo.match(/(class\s+\w+)\sextend+/i);

    if (resultado.length > 1) {
      return codigo.replace(resultado[1], `class ${nombre}`);
    } else {
      return codigo;
    }
  }
});