define("pilas-engine/templates/app/abrir-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a3NjCLJN",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"p\"],[11,\"class\",\"pa3 verdana f6\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"app.opening\"],null],false],[0,\" \"],[1,[25,[\"model\",\"ruta\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/app/abrir-proyecto.hbs"
    }
  });

  _exports.default = _default;
});