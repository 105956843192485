define("pilas-engine/templates/proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6aN5AiWK",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"sin_cabecera\"]]],null,{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"pilas-header-exportacion\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[\"flex \",[29,\"if\",[[25,[\"sin_cabecera\"]],\"vh-100\",\"vh-100-min-header\"],null]]]],[9],[0,\"\\n  \"],[1,[29,\"pilas-ejemplo\",null,[[\"modoZoom\",\"proyecto\"],[[25,[\"model\",\"modoZoom\"]],[25,[\"model\",\"proyecto\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/proyecto.hbs"
    }
  });

  _exports.default = _default;
});