define("pilas-engine/helpers/tiempo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tiempo = tiempo;
  _exports.default = void 0;

  function tiempo(params) {
    let tiempo = new Date(params * 1000).toISOString().substr(11, 5).split(":");
    return `${tiempo[0]} min ${tiempo[1]} seg`;
  }

  var _default = Ember.Helper.helper(tiempo);

  _exports.default = _default;
});