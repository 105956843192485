define("pilas-engine/components/pilas-boton-crear-carpeta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    escena: null,
    actions: {
      crear_carpeta() {
        if (!this.escena.carpetas) {
          this.escena.set("carpetas", Ember.A([]));
        }

        let nombre = "carpeta";
        this.escena.carpetas.pushObject(Ember.Object.create({
          nombre: nombre,
          id: Math.random()
        }));
      }

    }
  });

  _exports.default = _default;
});