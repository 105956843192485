define("pilas-engine/templates/components/pilas-selector-de-modo-zoom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8HvDdwqq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pt1\"],[9],[0,\"\\n  \"],[15,1],[0,\"\\n  \"],[7,\"select\"],[11,\"class\",\"select f7 mt1 verdana\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"cambiarModo\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n    \"],[7,\"option\"],[12,\"value\",2],[12,\"selected\",[29,\"eq\",[[25,[\"modoZoom\"]],2],null]],[9],[0,\"1:1\"],[10],[0,\"\\n    \"],[7,\"option\"],[12,\"value\",1],[12,\"selected\",[29,\"eq\",[[25,[\"modoZoom\"]],1],null]],[9],[1,[29,\"t\",[\"zoom.adjust\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-selector-de-modo-zoom.hbs"
    }
  });

  _exports.default = _default;
});