define("pilas-engine/routes/app/abrir-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel(model) {
      Ember.run.later(() => {
        this.transitionTo("editor", {
          queryParams: {
            ruta: model.ruta
          }
        });
      }, 100);
    }

  });

  _exports.default = _default;
});