define("pilas-engine/utils/recetas/actor/saltar-o-impulsar-cuando-hacen-click-en-la-pantalla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorSaltarOImpulsarCuandoHacenClickEnLaPantalla;

  function recetasActorSaltarOImpulsarCuandoHacenClickEnLaPantalla() {
    return {
      titulo: "Підсилення стрибків актора по кліку на екрані",
      icono: "receta_saltar",
      para: "actor",
      etiquetas: ["fisica", "impulsar", "saltar"],
      codigo: `
          cuando_hace_click_en_la_pantalla(x: number, y: number, evento) {
            this.impulsar(0, 30);
            this.pilas.reproducir_sonido("salto-corto");
          }
      `
    };
  }
});