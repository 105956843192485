define("pilas-engine/components/pilas-propiedad/imagen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mostrar: false,
    filtroPropuesto: "",
    tagName: "",
    actions: {
      al_cambiar(valor) {
        this.modificarAtributo(this.get("propiedad.propiedad"), valor);
        this.send("ocultar");
      },

      mostrar() {
        this.set("mostrar", true);
      },

      ocultar() {
        this.set("mostrar", false);
      },

      cuando_selecciona_imagen(imagen) {
        this.send("al_cambiar", imagen.nombre);
      }

    }
  });

  _exports.default = _default;
});