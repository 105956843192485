define("pilas-engine/templates/components/pilas-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hKGgdvlE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dib\"],[11,\"data-balloon-blunt\",\"\"],[12,\"data-balloon\",[23,\"texto\"]],[12,\"data-balloon-pos\",[23,\"pos\"]],[9],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-tooltip.hbs"
    }
  });

  _exports.default = _default;
});