define("pilas-engine/components/pilas-editor-panel-de-propiedades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      alternar(propiedad) {
        this.toggleProperty(propiedad);
      }

    }
  });

  _exports.default = _default;
});