define("pilas-engine/components/pilas-header-exportacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    router: Ember.inject.service(),
    actions: {
      abrir_en_el_editor() {
        this.router.transitionTo("editor", {
          queryParams: {
            hash: this.model.hash
          }
        });
      },

      pantalla_completa() {
        let pilas = document.querySelector("iframe").contentWindow.pilasengine;
        pilas.alternar_modo_pantalla_completa();
      }

    }
  });

  _exports.default = _default;
});