define("pilas-engine/components/pilas-boton-importar-sonido", ["exports", "pilas-engine/utils/obtener-contenido-en-base-64"], function (_exports, _obtenerContenidoEnBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      seleccionar_sonido() {
        this.element.querySelector("input").click();
      },

      procesar_sonido_seleccionado(event) {
        let encontrado = [...event.target.files].filter(archivo => {
          return archivo.size / 1024 > 400; // 400kb
        });

        if (encontrado.length > 0) {
          let archivo = encontrado[0];
          alert(`No se puede incorporar el archivo "${archivo.name}" porque excede los 400kb. Te recomendamos optimizar este archivo con un software como audacity para que pilas pueda procesarlo.`);
          return;
        }

        let promesas = [...event.target.files].map(archivo => {
          return (0, _obtenerContenidoEnBase.default)(archivo, "mp3|wav|ogg");
        });
        Ember.RSVP.all(promesas).then(lista_de_archivos => {
          this.cuando_procesa_archivos(lista_de_archivos);
        });
      }

    }
  });

  _exports.default = _default;
});