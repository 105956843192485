define("pilas-engine/components/pilas-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["absolute"],
    puede_cerrar: true,
    actions: {
      ocultar() {
        if (this.puede_cerrar) {
          this.alCerrar();
        }
      }

    }
  });

  _exports.default = _default;
});