define("pilas-engine/templates/components/pilas-inspector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vtnWTY1+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"verdana pa2 f6 unselectable pa0 flex1 overflow-y-auto hide-overflow-x ba borde \",[29,\"if\",[[25,[\"desactivado\"]],\"o-40 disabled\"],null]]]],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"instancia_seleccionada\"]]],null,{\"statements\":[[0,\"\\n\"],[0,\"    \"],[1,[29,\"component\",[[25,[\"componente_a_renderizar\"]]],[[\"instancia_seleccionada\",\"modificarAtributo\",\"cuando_cambia_un_nombre_de_actor\",\"cuando_cambia_un_nombre_de_escena\",\"cuando_modifica_atributo_de_actor\",\"cuando_modifica_atributo_de_escena\",\"cuando_modifica_atributo_del_proyecto\"],[[25,[\"instancia_seleccionada\"]],[25,[\"modificarAtributo\"]],[25,[\"cuando_cambia_un_nombre_de_actor\"]],[25,[\"cuando_cambia_un_nombre_de_escena\"]],[29,\"action\",[[24,0,[]],\"modificarAtributo\"],null],[29,\"action\",[[24,0,[]],\"modifica_atributo_de_escena\"],null],[29,\"action\",[[24,0,[]],\"modifica_atributo_del_proyecto\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-inspector.hbs"
    }
  });

  _exports.default = _default;
});