define("pilas-engine/utils/recetas/escena/crear-copias-de-un-actor-cada-determinado-tiempo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasEscenaCrearCopiasDeUnActorCadaDeterminadoTiempo;

  function recetasEscenaCrearCopiasDeUnActorCadaDeterminadoTiempo() {
    return {
      titulo: "Створювати копії актора через зазначений час в секундах",
      icono: "receta_tiempo",
      para: "escena",
      etiquetas: ["tiempo", "crear", "contar", "temporizado", "paso", "segundos"],
      codigo: `
          cada_segundo(segundos: number) {
              // Створювати клон актора Лазер кожні 5 секунд
              if (this.pilas.es_multiplo(segundos, 5)) {
                  this.pilas.clonar("laser");
              }
          }
      `
    };
  }
});