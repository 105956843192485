define("pilas-engine/components/pilas-propiedad/lasers", ["exports", "pilas-engine/utils/obtener-nombre-sin-repetir"], function (_exports, _obtenerNombreSinRepetir) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    lasers: Ember.computed("actor_id", "objeto", "propiedad.propiedad", function () {
      let valor = this.objeto.get(this.propiedad.propiedad);

      if (valor) {
        return JSON.parse(JSON.stringify(valor));
      } else {
        return [];
      }
    }),
    actions: {
      al_cambiar_valor(nombre, receptor, valor) {
        if (nombre !== "nombre") {
          valor = +valor;
        }

        Ember.set(receptor, nombre, valor);
        this.modificarAtributo(this.get("propiedad.propiedad"), this.lasers);
      },

      crear_un_laser() {
        let nombres_de_lasers = this.lasers.map(s => s.nombre);
        let nombre = (0, _obtenerNombreSinRepetir.default)(nombres_de_lasers, "laser");
        let id = Math.random();
        this.lasers.pushObject({
          id: `laser-${id}`,
          rotacion: 0,
          longitud: 100,
          etiquetas: [],
          nombre: nombre
        });
        this.modificarAtributo(this.get("propiedad.propiedad"), this.lasers);
      },

      eliminar_laser(laser) {
        this.lasers.removeObject(laser);
        this.modificarAtributo(this.get("propiedad.propiedad"), this.lasers);
      }

    }
  });

  _exports.default = _default;
});