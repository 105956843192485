define("pilas-engine/templates/components/blockly-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HfHDIA6r",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[29,\"if\",[[25,[\"desactivado\"]],\"absolute absolute--fill bg-black-50\",\"dn\"],null]],[9],[10],[0,\"\\n\\n\"],[7,\"iframe\"],[11,\"width\",\"100%\"],[11,\"height\",\"100%\"],[11,\"src\",\"bloques/bloques-editor.html\"],[11,\"sandbox\",\"allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts\"],[11,\"scrolling\",\"no\"],[11,\"border\",\"0\"],[11,\"frameborder\",\"0\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/blockly-editor.hbs"
    }
  });

  _exports.default = _default;
});