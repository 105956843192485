define("pilas-engine/fixtures/proyecto-inicial-plataformas-minimo", ["exports", "pilas-engine/fixtures/animaciones-iniciales", "pilas-engine/fixtures/workspace-bloques-de-escena-nueva", "pilas-engine/fixtures/sonidos-iniciales"], function (_exports, _animacionesIniciales, _workspaceBloquesDeEscenaNueva, _sonidosIniciales) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    titulo: "Proyecto",
    ancho: 500,
    alto: 500,
    tamaño: "500x500",
    nombre_de_la_escena_inicial: "escena1",
    imagenes: [],
    animaciones: _animacionesIniciales.default,
    codigos: {
      escenas: [{
        nombre: "escena1",
        codigo: "class escena1 extends Escena {\n    iniciar() {\n\n    }\n\n    actualizar() {\n\n    }\n}"
      }],
      actores: [{
        nombre: "actor",
        codigo: '// @ts-ignore\nclass actor extends Actor {\n  pies: Sensor;\n\n  iniciar() {\n    this.pies = this.obtener_sensor("pies");\n  }\n\n  actualizar() {\n\n    this.camara.seguir_al_actor(this, 10)\n\n    // control del personaje\n\n    if (this.control.izquierda) {\n      this.x -= 4;\n    }\n\n    if (this.control.derecha) {\n      this.x += 4;\n    }\n\n    if (this.control.arriba) {\n      // solo permite saltar si está tocando otra\n      // figura con el sensor pies (el de color rojo)\n      if (this.pies.cantidad_de_colisiones > 0) {\n        this.impulsar(0, 8)\n      }\n\n    }\n  }\n}\n'
      }, {
        nombre: "actor1",
        codigo: "// @ts-ignore\nclass actor1 extends Actor {\n\n  iniciar() {}\n\n  actualizar() {}\n}\n"
      }, {
        nombre: "actor2",
        codigo: "// @ts-ignore\nclass actor2 extends Actor {\n\n  iniciar() {}\n\n  actualizar() {}\n}\n"
      }, {
        nombre: "actor3",
        codigo: "// @ts-ignore\nclass actor3 extends Actor {\n\n  iniciar() {}\n\n  actualizar() {}\n}\n"
      }],
      proyecto: "class Proyecto {\n    iniciar() {\n    }\n}"
    },
    escenas: [{
      nombre: "escena1",
      id: 1,
      ancho: 1000,
      alto: 1000,
      camara_x: 1.8165304268846967,
      camara_y: 0,
      gravedad_x: 0,
      gravedad_y: 1,
      fondo: "decoracion:fondos/fondo-oliva",
      actores: [{
        x: -160.10498687664042,
        y: 105.86176727909012,
        z: 0,
        imagen: "imagenes:figuras/cuadrado_negro",
        centro_x: 0.5,
        centro_y: 0.5,
        rotacion: 0,
        escala_x: 1,
        escala_y: 1,
        transparencia: 0,
        etiqueta: "actor",
        espejado: false,
        espejado_vertical: false,
        figura: "rectangulo",
        figura_dinamica: true,
        figura_ancho: 62,
        figura_alto: 65,
        figura_radio: 40,
        figura_sin_rotacion: true,
        figura_rebote: 0.2999999999999995,
        figura_sensor: false,
        es_texto: false,
        texto_con_borde: false,
        id: 18607192927909904,
        activo: true,
        nombre: "actor",
        habilidades: [],
        sensores: [{
          id: "sensor-0.3156551160188761",
          x: 0,
          y: -28,
          ancho: 62,
          alto: 25,
          nombre: "pies"
        }],
        lasers: []
      }, {
        x: -59.559312579115584,
        y: -90.5476052550652,
        z: 0,
        imagen: "imagenes:figuras/cuadrado_azul",
        centro_x: 0.5,
        centro_y: 0.5,
        rotacion: 0,
        escala_x: 4.3199999999999985,
        escala_y: 0.2899999999999995,
        transparencia: 0,
        etiqueta: "actor",
        espejado: false,
        espejado_vertical: false,
        figura: "rectangulo",
        figura_dinamica: false,
        figura_ancho: 276,
        figura_alto: 19,
        figura_radio: 40,
        figura_sin_rotacion: false,
        figura_rebote: 1,
        figura_sensor: false,
        es_texto: false,
        texto_con_borde: false,
        id: 15179470419282624,
        activo: true,
        nombre: "actor1",
        habilidades: [],
        sensores: [],
        lasers: []
      }, {
        x: 332.82942493223777,
        y: -3.3723100688708882,
        z: 0,
        imagen: "imagenes:figuras/cuadrado_azul",
        centro_x: 0.5,
        centro_y: 0.5,
        rotacion: 0,
        escala_x: 4.3199999999999985,
        escala_y: 0.2899999999999995,
        transparencia: 0,
        etiqueta: "actor",
        espejado: false,
        espejado_vertical: false,
        figura: "rectangulo",
        figura_dinamica: false,
        figura_ancho: 276,
        figura_alto: 19,
        figura_radio: 40,
        figura_sin_rotacion: false,
        figura_rebote: 1,
        figura_sensor: false,
        es_texto: false,
        texto_con_borde: false,
        id: 16594955308868556,
        activo: true,
        nombre: "actor2",
        habilidades: [],
        sensores: [],
        lasers: []
      }, {
        x: 687.9792886924556,
        y: -116.92362705343044,
        z: 0,
        imagen: "imagenes:figuras/cuadrado_azul",
        centro_x: 0.5,
        centro_y: 0.5,
        rotacion: 0,
        escala_x: 4.3199999999999985,
        escala_y: 0.2899999999999995,
        transparencia: 0,
        etiqueta: "actor",
        espejado: false,
        espejado_vertical: false,
        figura: "rectangulo",
        figura_dinamica: false,
        figura_ancho: 276,
        figura_alto: 19,
        figura_radio: 40,
        figura_sin_rotacion: false,
        figura_rebote: 1,
        figura_sensor: false,
        es_texto: false,
        texto_con_borde: false,
        id: 15348473121153472,
        activo: true,
        nombre: "actor3",
        habilidades: [],
        sensores: [],
        lasers: []
      }]
    }],
    fps: 60,
    modo_de_video: "suavizado",
    sonidos: _sonidosIniciales.default,
    bloques: {
      proyecto: '<xml xmlns="https://developers.google.com/blockly/xml"><block type="actor_inicia" id="ZC`*TK^}PI+^~52^ak!H" x="35" y="44"></block></xml>',
      escenas: [{
        nombre: "escena1",
        bloques: {
          texto: _workspaceBloquesDeEscenaNueva.default,
          codigo_de_bloques: "if (this.id) {\n\tthis.pilas.notificar_ejecucion_del_bloque('^BS9[_V!D30$Klp?G]Nt', this.id);\n}\nactor._bloques_iniciar = function() {\n\n  };\n\nif (this.id) {\n\tthis.pilas.notificar_ejecucion_del_bloque('D[i2{g4SjDY+sAT7f=W@', this.id);\n}\nactor._bloques_actualizar = function() {\n\n  };\n"
        }
      }],
      actores: [{
        nombre: "actor",
        bloques: '<xml xmlns="https://developers.google.com/blockly/xml"><block type="actor_inicia" id="^BS9[_V!D30$Klp?G]Nt" x="-217" y="-205"></block><block type="actor_actualizar" id="D[i2{g4SjDY+sAT7f=W@" x="-212" y="-36"></block></xml>',
        codigo_de_bloques: "if (this.id) {\n\tthis.pilas.notificar_ejecucion_del_bloque('^BS9[_V!D30$Klp?G]Nt', this.id);\n}\nactor._bloques_iniciar = function() {\n\n  };\n\nif (this.id) {\n\tthis.pilas.notificar_ejecucion_del_bloque('D[i2{g4SjDY+sAT7f=W@', this.id);\n}\nactor._bloques_actualizar = function() {\n\n  };\n"
      }, {
        nombre: "actor1",
        bloques: '<xml xmlns="https://developers.google.com/blockly/xml"><block type="actor_inicia" id="^BS9[_V!D30$Klp?G]Nt" x="-217" y="-205"></block><block type="actor_actualizar" id="D[i2{g4SjDY+sAT7f=W@" x="-212" y="-36"></block></xml>',
        codigo_de_bloques: "if (this.id) {\n\tthis.pilas.notificar_ejecucion_del_bloque('^BS9[_V!D30$Klp?G]Nt', this.id);\n}\nactor._bloques_iniciar = function() {\n\n  };\n\nif (this.id) {\n\tthis.pilas.notificar_ejecucion_del_bloque('D[i2{g4SjDY+sAT7f=W@', this.id);\n}\nactor._bloques_actualizar = function() {\n\n  };\n"
      }, {
        nombre: "actor2",
        bloques: '<xml xmlns="https://developers.google.com/blockly/xml"></xml>',
        codigo_de_bloques: ""
      }, {
        nombre: "actor3"
      }]
    }
  };
  _exports.default = _default;
});