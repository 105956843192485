define("pilas-engine/templates/components/pilas-inspector/escena", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZEtZsmMP",
    "block": "{\"symbols\":[\"propiedad\"],\"statements\":[[1,[29,\"pilas-propiedad/nombre\",null,[[\"nombre\",\"objeto\",\"cuando_cambia\"],[[25,[\"instancia_seleccionada\",\"nombre\"]],[25,[\"instancia_seleccionada\"]],[25,[\"cuando_cambia_un_nombre_de_escena\"]]]]],false],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"propiedades\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pb1\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[29,\"eq\",[[24,1,[\"tipo\"]],\"numero\"],null]],null,{\"statements\":[[0,\"\\n      \"],[1,[29,\"pilas-propiedad/numero\",null,[[\"objeto\",\"etiqueta\",\"min\",\"max\",\"propiedad\",\"intensidad\",\"modificarAtributo\"],[[25,[\"instancia_seleccionada\"]],[24,1,[\"etiqueta\"]],[24,1,[\"min\"]],[24,1,[\"max\"]],[24,1,[]],[24,1,[\"intensidad\"]],[25,[\"cuando_modifica_atributo_de_escena\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[24,1,[\"tipo\"]],\"imagen\"],null]],null,{\"statements\":[[0,\"\\n      \"],[1,[29,\"pilas-propiedad/imagen\",null,[[\"objeto\",\"etiqueta\",\"propiedad\",\"filtroPropuesto\",\"modificarAtributo\"],[[25,[\"instancia_seleccionada\"]],[24,1,[\"etiqueta\"]],[24,1,[]],[24,1,[\"filtroPropuesto\"]],[25,[\"cuando_modifica_atributo_de_escena\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[24,1,[\"tipo\"]],\"separador\"],null]],null,{\"statements\":[[0,\"\\n      \"],[1,[29,\"pilas-propiedad/separador\",null,[[\"etiqueta\",\"propiedad\"],[[24,1,[\"etiqueta\"]],[24,1,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"red\"],[9],[0,\"Немає обробника для \"],[1,[24,1,[\"propiedad\"]],false],[10],[0,\"\\n\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-inspector/escena.hbs"
    }
  });

  _exports.default = _default;
});