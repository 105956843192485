define("pilas-engine/helpers/json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.json = json;
  _exports.default = void 0;

  function json(params) {
    return JSON.stringify(params[0], null, 2);
  }

  var _default = Ember.Helper.helper(json);

  _exports.default = _default;
});