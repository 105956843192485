define("pilas-engine/templates/components/pilas-boton-webserver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NRFtPvJ8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"iniciando\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"pilas-boton\",null,[[\"responsive\",\"icono\",\"desactivado\",\"texto\"],[true,\"webserver\",true,[29,\"t\",[\"server.starting\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"deteniendo\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"pilas-boton\",null,[[\"responsive\",\"icono\",\"desactivado\",\"texto\"],[true,\"webserver\",true,[29,\"t\",[\"server.stoping\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"activado\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"pilas-boton\",null,[[\"responsive\",\"icono\",\"texto\",\"accion\"],[true,\"webserver\",[29,\"t\",[\"server.stop\"],null],[29,\"action\",[[24,0,[]],\"detener_servidor\"],null]]]],false],[0,\"\\n\\n      \"],[7,\"span\"],[11,\"class\",\"texto f7 pt2 mt1 dib unselectable pointer\"],[11,\"role\",\"button\"],[9],[1,[23,\"direccion\"],false],[3,\"action\",[[24,0,[]],\"abrir_en_un_navegador\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"pilas-boton\",null,[[\"responsive\",\"icono\",\"desactivado\",\"accion\",\"texto\"],[true,\"webserver\",[25,[\"cargando\"]],[29,\"action\",[[24,0,[]],\"cuando_activa_webserver\"],null],[29,\"t\",[\"server.start\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-webserver.hbs"
    }
  });

  _exports.default = _default;
});