define("pilas-engine/components/pilas-propiedad/nombre-del-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mostrar: false,
    proyecto: Ember.inject.service(),
    tagName: "span",

    didInsertElement() {
      let titulo = this.proyecto.get("proyecto.titulo");
      this.set("nombreSugerido", titulo);
      this.set("nombre", titulo);
    },

    hacer_foco() {
      let input = this.element.getElementsByTagName("input")[0];
      input.focus();
      input.select();
    },

    invalido: Ember.computed("nombreSugerido", function () {
      return this.nombreSugerido.length < 1;
    }),
    actions: {
      cambiarNombre() {
        this.set("mostrar", true);
        Ember.run.later(this, this.hacer_foco, 1);
      },

      ocultar() {
        this.set("mostrar", false);
      },

      confirmar() {
        if (this.invalido) {
          return;
        }

        this.proyecto.renombrar_proyecto(this.nombreSugerido);
        this.send("ocultar");
        this.set("nombre", this.nombreSugerido);
      }

    }
  });

  _exports.default = _default;
});