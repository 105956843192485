define("pilas-engine/templates/components/pilas-grilla-de-actores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LbHT7n6b",
    "block": "{\"symbols\":[\"actor\",\"&default\"],\"statements\":[[15,2],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"tl mb2\"],[9],[0,\"\\n  \"],[1,[29,\"pilas-input-filtro\",null,[[\"filtro\"],[[25,[\"filtro\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"actores\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"pilas-celda-de-actor\",null,[[\"filtro\",\"actor_seleccionado\",\"actor\",\"cuandoQuiereCrearActor\"],[[25,[\"filtro\"]],[25,[\"actor_seleccionado\"]],[24,1,[]],[25,[\"cuandoQuiereCrearActor\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-grilla-de-actores.hbs"
    }
  });

  _exports.default = _default;
});