define("pilas-engine/templates/components/pilas-selector-de-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kSyW4oOz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pa1 pointer hover-bg-black-10\"],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[\"dib br-100 \",[23,\"color\"],\" icono-item-de-actor\"]]],[9],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[25,[\"accion\"]],[25,[\"color\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-selector-de-color.hbs"
    }
  });

  _exports.default = _default;
});