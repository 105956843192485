define("pilas-engine/templates/components/iniciar-proyecto/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4YPWym3w",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"editor\",[29,\"query-params\",null,[[\"tipo\"],[[25,[\"nombre\"]]]]]],[[\"id\",\"tagName\",\"class\"],[[25,[\"nombre\"]],\"div\",\"dib w180 pointer\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"style\",\"width: 180px; height: 140px;\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[30,[\"sprite-iniciar-proyecto capturas-\",[23,\"nombre\"]]]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"bg-black-80 pa1 white tc lato f7 bl bb br b--black-90 truncate\"],[9],[0,\"\\n    \"],[1,[23,\"titulo\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/iniciar-proyecto/item.hbs"
    }
  });

  _exports.default = _default;
});