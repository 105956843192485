define("pilas-engine/components/pilas-acerca-de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    parrafos: Ember.computed('intl.locale', function () {
      return this.intl.t('about.text').split("\n\n");
    })
  });

  _exports.default = _default;
});