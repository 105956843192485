define("pilas-engine/components/pilas-boton-principal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    class: `
    ba link br2 pa3 w-30-ns w-100
    verdana f6 no-underline black
    b--black-10 pointer
    ma2
    bg-primario
    truncate
    unselectable
    undraggable
    boton boton-principal
  `
  });

  _exports.default = _default;
});