define("pilas-engine/components/pilas-propiedad/habilidades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    habilidades: null,

    init() {
      this._super(...arguments);

      this.set("habilidades", ["перетягуватися мишкою", "постійно обертатися", "керуватися клавіатурою", "повільно рухатися за мишкою", "рухатися з мишкою", "коливатися вертикально", "коливатися обертами", "зникати та з'являтися" //
      ]);
    },

    actions: {
      cuando_selecciona(valor) {
        this.modificarAtributo(this.get("propiedad.propiedad"), valor);
      }

    }
  });

  _exports.default = _default;
});