define("pilas-engine/controllers/manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    seccion: "index",
    queryParams: ["seccion"],
    electron: Ember.inject.service(),
    actions: {
      cuandoCambiaURL(url) {
        this.set("seccion", url);
      },

      abrirEnUnaVentanaNueva() {
        if (this.electron.enElectron) {
          let base = window.location.href.split("index.html")[0];
          window.open(`${base}/manual/index.html`);
        } else {
          let protocolo = window.location.protocol;
          let host = window.location.host;
          window.open(`${protocolo}//${host}/manual/${this.seccion}.html`);
        }
      }

    }
  });

  _exports.default = _default;
});