define("pilas-engine/routes/app/abrir-proyecto-serializado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    proyecto: Ember.inject.service(),

    model(params) {
      return {
        hash: params.hash
      };
    },

    afterModel(model) {
      Ember.run.later(() => {
        this.proyecto.guardar_proyecto_serializado(model.hash);
        this.transitionTo("editor");
      }, 1);
    }

  });

  _exports.default = _default;
});