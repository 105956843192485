define("pilas-engine/utils/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HOST = window.location.origin;

  if (window.location.host) {
    HOST = window.location.origin;
  }
  /*
  if (window.location.host) {
    HOST = "*";
    //"http://" + window.location.host;
    //console.log({ HOST, origin: window.location.origin });
  }
  */


  var _default = {
    HOST
  };
  _exports.default = _default;
});