define("pilas-engine/services/ejemplos", ["exports", "ember-concurrency", "pilas-engine/config/environment", "pilas-engine/utils/convertir-proyecto-en-objeto-ember"], function (_exports, _emberConcurrency, _environment, _convertirProyectoEnObjetoEmber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    cache: null,
    migraciones: Ember.inject.service(),
    tarea: (0, _emberConcurrency.task)(function* () {
      if (this.cache) {
        return this.cache;
      }

      yield (0, _emberConcurrency.timeout)(500);
      let data = yield this.obtenerEjemplos();
      this.set("cache", data);
      return data;
    }).drop(),

    obtenerEjemplos() {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", `${_environment.default.rootURL}ejemplos/ejemplos.json`);
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject(xhr.status);
          }
        };

        xhr.send();
      });
    },

    obtener() {
      return this.tarea.perform();
    },

    obtener_por_nombre(nombre) {
      return this.obtener_ejemplo(nombre).then(data => {
        let proyecto = this.migraciones.migrar((0, _convertirProyectoEnObjetoEmber.default)(data));
        let anterior = null;
        let siguiente = null;
        return {
          nombre: nombre,
          anterior: anterior,
          siguiente: siguiente,
          ejemplo: {
            proyecto: proyecto,
            //
            nombre: nombre
          },
          modoZoom: 2
        };
      });
    },

    obtener_ejemplo(nombre) {
      let url = `${_environment.default.rootURL}ejemplos/proyectos/${nombre}.pilas`;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);

        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject(xhr.status);
          }
        };

        xhr.send();
      });
    },

    obtener_anterior(nombre) {
      let indice = this.cache.ejemplos.findIndex(e => e.nombre == nombre);

      if (indice > 0) {
        return this.cache.ejemplos[indice - 1].nombre;
      }
    },

    obtener_siguiente(nombre) {
      let indice = this.cache.ejemplos.findIndex(e => e.nombre == nombre);

      if (indice < this.cache.ejemplos.length - 1) {
        return this.cache.ejemplos[indice + 1].nombre;
      }
    }

  });

  _exports.default = _default;
});