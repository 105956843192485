define("pilas-engine/components/pilas-celda-de-imagen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    debeMostrar: Ember.computed("filtro", "imagen.nombre", function () {
      let nombre = this.get("imagen.nombre");

      if (this.filtro) {
        return nombre.indexOf(this.filtro) > -1;
      } else {
        return true;
      }
    }),
    actions: {
      cuando_selecciona_imagen(imagen) {
        this.cuando_selecciona(imagen);
      }

    }
  });

  _exports.default = _default;
});