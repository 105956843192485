define("pilas-engine/components/pilas-botones-para-deshacer-y-rehacer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    memento: Ember.inject.service(),
    deshacer: null,
    // acción que se envía como parámetro.
    rehacer: null // acción que se envía como parámetro.

  });

  _exports.default = _default;
});