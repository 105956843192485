define("pilas-engine/templates/iniciar-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TWrDgiI9",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"pantalla/iniciar-proyecto\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/iniciar-proyecto.hbs"
    }
  });

  _exports.default = _default;
});