define("pilas-engine/templates/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x4jZwmYN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pa5 theme-oscuro bg-fondo texto vh-100\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"verdana f6 tc pa2\"],[9],[1,[29,\"pilas-spinner\",null,[[\"s\"],[40]]],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"verdana f6 tc texto\"],[9],[1,[29,\"t\",[\"loading\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/application-loading.hbs"
    }
  });

  _exports.default = _default;
});