define("pilas-engine/templates/components/pilas-boton-crear-carpeta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ek85yGk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[7,\"div\"],[11,\"role\",\"button\"],[11,\"id\",\"boton-crear-carpeta\"],[11,\"class\",\"dib mr1 bg-transparent no-outline p0 f7 o-50 pointer glow bn pa0\"],[9],[0,\"\\n\\n  \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[\"crear-carpeta\"]]],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"crear_carpeta\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-crear-carpeta.hbs"
    }
  });

  _exports.default = _default;
});