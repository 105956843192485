define("pilas-engine/templates/components/pilas-indicador-inactivo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/AuGr9SO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dib\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"o-50 bn pa0\"],[9],[1,[29,\"pilas-icono\",null,[[\"icono\"],[\"inactivo\"]]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-indicador-inactivo.hbs"
    }
  });

  _exports.default = _default;
});