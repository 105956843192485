define("pilas-engine/services/api", ["exports", "pilas-engine/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    electron: Ember.inject.service(),
    enElectron: Ember.computed.alias("electron.enElectron"),

    autenticar(usuario, contraseña) {
      return this.post("login/", {
        username: usuario,
        password: contraseña
      });
    },

    cerrarSesion(token) {
      return this.get("logout/", {
        token
      });
    },

    buscarEtiquetas(query) {
      return this.get(`buscar-etiquetas/?query=${query}`);
    },

    obtenerPerfilDesdeToken(token) {
      return this.get(`perfiles/mi-perfil`, {
        token
      });
    },

    post(endpoint, datos, token) {
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        let url = null;

        if (this.enElectron) {
          url = `${_environment.default.remoteBackendURL}/${endpoint}`;
        } else {
          url = `${_environment.default.backendURL}/${endpoint}`;
        }

        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-type", "application/json");

        if (token) {
          xhr.setRequestHeader("authorization", `Token ${token}`);
        }

        xhr.onload = function () {
          if (xhr.status == 200) {
            let json = JSON.parse(xhr.responseText);
            resolve(json);
          } else {
            reject(JSON.parse(xhr.response));
          }
        };

        xhr.onerror = function (error) {
          console.log("error", xhr.responseText);
          reject({
            url,
            error
          });
        };

        xhr.send(JSON.stringify(datos));
      });
    },

    crearUsuario(usuario, contraseña, email) {
      return this.post("perfiles/crear-usuario", {
        usuario,
        password: contraseña,
        email
      });
    },

    publicar_juego(serializado, imagen_en_base64, ver_codigo, tags, titulo, token, cantidad_de_partes, numero_de_parte, hash) {
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        let url = null;

        if (this.enElectron) {
          url = `${_environment.default.remoteBackendURL}/proyecto/subir`;
        } else {
          url = `${_environment.default.backendURL}/proyecto/subir`;
        }

        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-type", "application/json");

        if (token) {
          xhr.setRequestHeader("authorization", `Token ${token}`);
        }

        xhr.onload = function () {
          if (xhr.status == 200) {
            let json = JSON.parse(xhr.responseText);
            resolve(json);
          } else {
            reject(url);
          }
        };

        xhr.onerror = function () {
          reject(url);
        };

        let data_original = {
          codigo_serializado: serializado,
          ver_codigo: ver_codigo,
          cantidad_de_partes,
          numero_de_parte,
          titulo,
          tags
        };

        if (imagen_en_base64) {
          data_original["imagen_en_base64"] = imagen_en_base64;
        } // Solo incluye el hash cuando se trata de una
        // parte complementaria al post inicial.


        if (hash) {
          data_original = { ...data_original,
            hash
          };
        }

        var data = JSON.stringify(data_original);
        xhr.send(data);
      });
    },

    obtener_lista_de_proyectos(pagina, etiqueta, soloMisJuegos, mostrarRecientesAgrupados) {
      pagina = pagina || 1;
      etiqueta = etiqueta || null;
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        let url = `${_environment.default.backendURL}/explorar/?pagina=${pagina}`;

        if (soloMisJuegos) {
          url += `&solo_mis_juegos=${soloMisJuegos}`;
        }

        if (etiqueta) {
          url += `&etiqueta=${etiqueta}`;

          if (mostrarRecientesAgrupados) {
            url += `&mostrar_recientes_agrupados=true`;
          }
        }

        xhr.open("GET", url, true);

        if (soloMisJuegos) {
          let token = localStorage.getItem("token-auth");
          xhr.setRequestHeader("authorization", `Token ${token}`);
        }

        xhr.onload = function () {
          if (xhr.status == 200) {
            let json = JSON.parse(xhr.responseText);
            resolve(json);
          } else {
            reject(url);
          }
        };

        xhr.onerror = function () {
          reject(url);
        };

        xhr.send();
      });
    },

    obtener_proyecto(hash) {
      return this.get(`proyecto/obtener/${hash}`);
    },

    get(endpoint, headers) {
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        let url = `${_environment.default.backendURL}/${endpoint}`;
        xhr.open("GET", url, true);

        if (headers && headers.token) {
          xhr.setRequestHeader("authorization", `Token ${headers.token}`);
        }

        xhr.onload = function () {
          if (xhr.status == 200) {
            let json = JSON.parse(xhr.responseText);
            resolve(json);
          } else {
            reject(url);
          }
        };

        xhr.onerror = function () {
          reject(url);
        };

        xhr.send();
      });
    },

    eliminar_proyecto(hash) {
      let token = localStorage.getItem("token-auth");
      return this.post(`proyecto/eliminar_proyecto/`, {
        hash
      }, token);
    }

  });

  _exports.default = _default;
});