define("pilas-engine/templates/components/monaco-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UyAkI8lo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\"],[12,\"class\",[30,[\"height-revert \",[29,\"if\",[[25,[\"desactivado\"]],\"o-50\"],null]]]],[11,\"width\",\"100%\"],[11,\"height\",\"100%\"],[11,\"src\",\"editor/monaco-editor.html\"],[11,\"sandbox\",\"allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts\"],[11,\"scrolling\",\"no\"],[11,\"border\",\"0\"],[11,\"frameborder\",\"0\"],[12,\"name\",[23,\"elementId\"]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/monaco-editor.hbs"
    }
  });

  _exports.default = _default;
});