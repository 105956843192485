define("pilas-engine/helpers/helper-reproducir-musica", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reproducirMusica = reproducirMusica;
  _exports.default = void 0;

  function reproducirMusica(params) {
    let nombre = params[0];
    return `this.reproducir_musica("${nombre}");`;
  }

  var _default = Ember.Helper.helper(reproducirMusica);

  _exports.default = _default;
});