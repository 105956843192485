define("pilas-engine/services/sesion", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    autenticado: false,
    autenticandoEnCurso: false,
    api: Ember.inject.service(),
    perfil: false,

    // este método se llama automáticamente cuando la aplicación inicia
    // (ver archivo app/routes/application.js)
    iniciar() {
      if (this.autenticado) {
        return;
      }

      if (localStorage.getItem("token-auth")) {
        this.obtenerDatosDeUsuario.perform();
      } else {
        this.set("autenticado", false);
      }
    },

    registrarLogin(token) {
      // se invoca cuando el component pilas-boton-login logra hacer una
      // autenticación exitosa.
      localStorage.setItem("token-auth", token);
      return this.obtenerDatosDeUsuario.perform();
    },

    obtenerDatosDeUsuario: (0, _emberConcurrency.task)(function* () {
      if (this.autenticandoEnCurso) {
        console.log("evitando obtener datos de usuario porque está autenticando en curso");
        return;
      }

      this.set("autenticandoEnCurso", true);
      let token = localStorage.getItem("token-auth");
      yield (0, _emberConcurrency.timeout)(500);

      try {
        let respuesta = yield this.api.obtenerPerfilDesdeToken(token);
        this.set("perfil", respuesta);
        this.set("autenticado", true);
        this.set("autenticandoEnCurso", false);
      } catch (e) {
        console.log(e);
        this.set("autenticado", false);
        this.set("autenticandoEnCurso", false);
      }
    }),

    cerrarSesion() {
      this.set("autenticado", false);
      this.set("autenticandoEnCurso", false);
      let token = localStorage.getItem("token-auth");
      localStorage.removeItem("token-auth");
      return this.api.cerrarSesion(token);
    }

  });

  _exports.default = _default;
});