define("pilas-engine/components/pilas-ejemplos-es-nuevo", ["exports", "pilas-engine/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      version
    }
  } = _environment.default;

  var _default = Ember.Component.extend({
    esNuevo: Ember.computed("ejemplo.desde", function () {
      if (!this.ejemplo || !this.ejemplo.desde) {
        return false;
      }

      let version_actual = version.split("+")[0];
      return this.ejemplo.desde >= version_actual;
    })
  });

  _exports.default = _default;
});