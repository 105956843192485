define("pilas-engine/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    electron: Ember.inject.service(),
    bus: Ember.inject.service(),
    sesion: Ember.inject.service(),
    log: Ember.inject.service(),
    actores: Ember.inject.service(),
    estadisticas: Ember.inject.service(),
    recursos: Ember.inject.service(),
    intl: Ember.inject.service(),

    model() {
      this.electron.iniciar();
      this.bus.iniciar();
      this.log.iniciar();
      this.estadisticas.iniciar();
      this.sesion.iniciar();
      return Ember.RSVP.hash({
        actores: this.actores.iniciar(),
        recursos: this.recursos.iniciar()
      });
    },

    beforeModel() {
      this._super(...arguments);

      this.intl.setLocale(['uk']);
    }

  });

  _exports.default = _default;
});