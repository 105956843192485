define("pilas-engine/utils/obtener-plantilla-de-escena", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = obtener_plantilla_de_escena;

  function obtener_plantilla_de_escena() {
    return `class Escena1 extends Escena {
    
  iniciar() {

  }

  actualizar() {

  }

}`;
  }
});