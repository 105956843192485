define("pilas-engine/components/pilas-propiedad/interruptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      cuando_cambia(valor) {
        let nombre_de_la_propiedad = this.get("propiedad.propiedad");
        this.modificarAtributo(nombre_de_la_propiedad, valor);
      }

    }
  });

  _exports.default = _default;
});