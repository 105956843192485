define("pilas-engine/components/selector-de-codigo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    filtro: "",
    contador: 0,
    // se utiliza para recalcular la lista al abrir el menú.
    bus: Ember.inject.service(),
    entidades_filtradas: Ember.computed("filtro", "entidades", function () {
      let f = this.filtro.toLowerCase();
      return this.entidades.filter(e => e.nombre.toLowerCase().includes(f));
    }),
    los_actores: Ember.computed("proyecto.escenas.@each.actores", function () {
      return this.get("proyectos.escenas.@each.actores");
    }),
    entidades: Ember.computed("contador", "los_actores.@each", function () {
      let lista = [];
      lista.pushObject({
        nombre: "Proyecto",
        id: "proyecto",
        tipo: "proyecto"
      });
      this.proyecto.escenas.map(escena => {
        lista.pushObject({
          nombre: escena.nombre,
          id: escena.id,
          tipo: "escena"
        });
        escena.actores.map(actor => {
          lista.pushObject({
            nombre: actor.nombre,
            color: actor.color,
            id: actor.id,
            tipo: "actor"
          });
        });
      });
      return lista;
    }),
    actions: {
      seleccionarEntidad(entidad, dd) {
        this.cuandoSelecciona(entidad.id);
        dd.actions.close();
      },

      cuandoDejaElInput(dd) {
        Ember.run.later(() => {
          dd.actions.close();
          this.set("filtro", "");
        }, 100);
      },

      cuando_abre() {
        Ember.run.later(() => {
          this.incrementProperty("contador");
          let filtro = document.getElementById("input-filtro-de-codigos");
          filtro.focus();
        }, 1);
      },

      cuandoPulsaEnter(dd) {
        if (this.entidades_filtradas.length > 0) {
          this.send("seleccionarEntidad", this.entidades_filtradas.firstObject, dd);
          this.bus.trigger("hacerFocoEnElEditor", {});
        }
      }

    }
  });

  _exports.default = _default;
});