define("pilas-engine/templates/components/pilas-selector-de-idioma", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c6rTbpra",
    "block": "{\"symbols\":[\"idioma\",\"&default\"],\"statements\":[[15,2],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"fixed top-1 right-1 verdana flex f7 gray\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"idiomas\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[[29,\"if\",[[29,\"eq\",[[25,[\"seleccion\"]],[24,1,[]]],null],\"black\"],null],\" ttu pa1 hover-bg-black-10 br2 pointer\"]]],[11,\"role\",\"button\"],[9],[0,\"\\n      \"],[1,[24,1,[]],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"definirIdioma\",[24,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-selector-de-idioma.hbs"
    }
  });

  _exports.default = _default;
});