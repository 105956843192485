define("pilas-engine/components/pilas-indicador-de-errores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      cerrar(dd) {
        dd.actions.close();
      },

      cuando_abre() {
        Ember.run.later(() => {
          let filtro = document.getElementById("input-filtro-para-ocultar");
          filtro.focus();
        }, 100);
      }

    }
  });

  _exports.default = _default;
});