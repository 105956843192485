define("pilas-engine/utils/recetas/actor/detecta-el-paso-del-tiempo-en-segundos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorDetectaElPasoDelTiempoEnSegundos;

  function recetasActorDetectaElPasoDelTiempoEnSegundos() {
    return {
      titulo: "Визначити плин часу в секундах",
      icono: "receta_tiempo",
      para: "actor",
      etiquetas: ["tiempo", "crear", "contar", "temporizado", "paso", "segundos"],
      codigo: `
            cada_segundo(segundos: number) {
                this.decir("Пройшло " + segundos + " секунд")

                // Кожні 5 секунд повідомлення змінюється
                if (this.pilas.es_multiplo(segundos, 5)) {
                    this.decir("Кратне 5!!!")
                }
            }
        `
    };
  }
});