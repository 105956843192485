define("pilas-engine/components/pilas-portada", ["exports", "pilas-engine/utils/json-a-string"], function (_exports, _jsonAString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["theme-claro", "h-100", "overflow-auto"],
    router: Ember.inject.service(),
    clase: "",

    dragOver() {
      event.preventDefault();
      this.set("clase", "bg-light-yellow");
    },

    dragLeave(event) {
      event.preventDefault();
      this.set("clase", "");
    },

    drop(evento) {
      let nombre_del_archivo = evento.dataTransfer.files[0].name;

      if (nombre_del_archivo.endsWith(".pilas")) {
        var reader = new FileReader();

        reader.onload = event => {
          this.router.transitionTo("app.abrir_proyecto_serializado", (0, _jsonAString.default)(JSON.parse(event.target.result)));
        };

        reader.readAsText(evento.dataTransfer.files[0], "UTF-8");
      } else {
        alert("Solo se pueden arrastrar archivos de proyectos '.pilas'.");
      }

      evento.preventDefault();
    }

  });

  _exports.default = _default;
});