define("pilas-engine/templates/components/pilas-panel-de-escenas/item-escena", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E08//0fI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[11,\"role\",\"button\"],[12,\"class\",[30,[\"pointer texto truncate pa2 \",[29,\"if\",[[29,\"and\",[[29,\"eq\",[[25,[\"ultimaEscenaSeleccionada\"]],[25,[\"escena\",\"id\"]]],null],[29,\"not-eq\",[[25,[\"seleccion\"]],0],null]],null],\"bg-black-20\",\"hover-bg-black-10\"],null]]]],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"colapsador\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"ultimaEscenaSeleccionada\"]],[25,[\"escena\",\"id\"]]],null]],null,{\"statements\":[[0,\"      ▾\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      ▸\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"span\"],[11,\"data-test\",\"nombre-de-escena\"],[9],[1,[25,[\"escena\",\"nombre\"]],false],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"dib fr f7\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[29,\"eq\",[[25,[\"ultimaEscenaSeleccionada\"]],[25,[\"escena\",\"id\"]]],null],[29,\"not-eq\",[[25,[\"seleccion\"]],0],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"pilas-boton-crear-carpeta\",null,[[\"escena\"],[[25,[\"escena\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[29,\"eq\",[[25,[\"ultimaEscenaSeleccionada\"]],[25,[\"escena\",\"id\"]]],null],[29,\"not-eq\",[[25,[\"seleccion\"]],0],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"pilas-boton-eliminar-con-confirmacion\",null,[[\"contador\",\"tipo\",\"titulo\",\"accion\"],[true,\"escena\",[29,\"t\",[\"panel.remove.scene\"],null],[29,\"action\",[[24,0,[]],[25,[\"cuando_intenta_eliminar\"]],[25,[\"escena\",\"id\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[3,\"action\",[[24,0,[]],[25,[\"cuandoSelecciona\"]],[25,[\"escena\",\"id\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-panel-de-escenas/item-escena.hbs"
    }
  });

  _exports.default = _default;
});