define("pilas-engine/components/pilas-editor-panel-de-juego-con-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    maximizar: false,
    memento: Ember.inject.service(),
    bus: Ember.inject.service(),
    actions: {
      alCambiarMaximizado(valor) {
        if (valor) {
          this.set("panelMaximizado", "canvas");
        } else {
          this.set("panelMaximizado", null);
        }
      }

    }
  });

  _exports.default = _default;
});