define("pilas-engine/controllers/editor", ["exports", "pilas-engine/utils/json-a-string", "pilas-engine/utils/string-a-json", "ember-parachute", "ember-concurrency", "pilas-engine/fixtures/proyecto-inicial", "pilas-engine/fixtures/proyecto-inicial-desde-cero", "pilas-engine/fixtures/proyecto-inicial-plataformas-animado", "pilas-engine/fixtures/proyecto-inicial-plataformas-minimo", "pilas-engine/fixtures/proyecto-inicial-naves-minimo", "pilas-engine/fixtures/proyecto-inicial-calle", "pilas-engine/utils/convertir-proyecto-en-objeto-ember"], function (_exports, _jsonAString, _stringAJson, _emberParachute, _emberConcurrency, _proyectoInicial, _proyectoInicialDesdeCero, _proyectoInicialPlataformasAnimado, _proyectoInicialPlataformasMinimo, _proyectoInicialNavesMinimo, _proyectoInicialCalle, _convertirProyectoEnObjetoEmber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const queryParams = new _emberParachute.default({
    tipo: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    ruta: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    mostrarEditor: {
      as: "p3",
      defaultValue: false,
      replace: false
    },
    mostrarPropiedades: {
      as: "p1",
      defaultValue: true,
      replace: false
    },
    escenaActual: {
      defaultValue: 1,
      replace: true
    },
    actorSeleccionado: {
      defaultValue: -1,
      replace: true
    },
    ultimaEscenaSeleccionada: {
      defaultValue: 1,
      replace: true
    },
    mostrarModalCreacionDeActor: {
      defaultValue: false,
      replace: true
    },
    mostrarInterprete: {
      defaultValue: false,
      replace: true
    },
    pos: {
      defaultValue: false,
      replace: true
    },
    fps: {
      defaultValue: true,
      replace: true
    },
    minimapa: {
      defaultValue: true,
      replace: true
    },
    fisica: {
      defaultValue: true,
      replace: true
    },
    fisica_en_modo_ejecucion: {
      defaultValue: false,
      replace: false
    },
    permitir_modo_pausa: {
      defaultValue: true,
      replace: true
    },
    oscuro: {
      defaultValue: true,
      replace: true
    },
    modoVim: {
      defaultValue: false,
      replace: true
    },
    modoZoom: {
      defaultValue: 1,
      replace: true
    },
    zoom: {
      defaultValue: 1,
      replace: true
    },
    grilla: {
      defaultValue: 0,
      replace: true
    },
    pixelart: {
      defaultValue: false,
      replace: true
    },
    ejemplo: {
      defaultValue: null,
      replace: true,
      refresh: true
    },
    hash: {
      defaultValue: null,
      replace: true,
      refresh: true
    },
    tamano: {
      defaultValue: 16,
      replace: true,
      refresh: true
    },
    mostrarModalDeAnimaciones: {
      defaultValue: false,
      replace: true
    },
    mostrarModalDeSonidos: {
      defaultValue: false,
      replace: true
    },
    mostrarModalDeAyuda: {
      defaultValue: false,
      replace: true
    },
    mostrarBloques: {
      defaultValue: false,
      replace: true
    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    proyecto: null,
    bus: Ember.inject.service(),
    intl: Ember.inject.service(),
    ejemplos: Ember.inject.service(),
    electron: Ember.inject.service(),
    router: Ember.inject.service(),
    api: Ember.inject.service(),
    migraciones: Ember.inject.service(),
    servicioProyecto: Ember.inject.service("proyecto"),

    setup(event) {
      this.tareaCargarProyecto.perform(event.queryParams);
    },

    tareaCargarProyecto: (0, _emberConcurrency.task)(function* (params) {
      if (params.ejemplo) {
        let proyecto = yield this.cargarProyectoDesdeEjemplo.perform(params.ejemplo);
        return this.migraciones.migrar(proyecto);
      }

      if (params.hash) {
        let datos = yield this.cargarProyectoDesdeHashDelBackend.perform(params.hash);

        if (!datos.ver_codigo) {
          alert("Lo siento, el código de este proyecto no se puede acceder.");
          return this.router.transitionTo("index");
        } else {
          return this.migraciones.migrar(datos);
        }
      }

      if (params.ruta) {
        let proyecto = yield this.cargar_proyecto_desde_ruta_archivo.perform(params.ruta);
        return this.migraciones.migrar(proyecto);
      }

      let tipo = params.tipo;

      if (tipo) {
        // Restaura el tipo a null por si se recarga la página.
        this.set("tipo", null);
      }

      if (tipo === "nuevo") {
        return this.crear_proyecto_desde_fixture(_proyectoInicialDesdeCero.default);
      }

      if (tipo === "plataformas-animado") {
        return this.crear_proyecto_desde_fixture(_proyectoInicialPlataformasAnimado.default);
      }

      if (tipo === "plataformas-minimo") {
        return this.crear_proyecto_desde_fixture(_proyectoInicialPlataformasMinimo.default);
      }

      if (tipo === "nave-minimo") {
        return this.crear_proyecto_desde_fixture(_proyectoInicialNavesMinimo.default);
      }

      if (tipo === "calle") {
        return this.crear_proyecto_desde_fixture(_proyectoInicialCalle.default);
      }

      if (tipo === "continuar" && localStorage.getItem("pilas:proyecto_serializado")) {
        let proyecto_serializado = localStorage.getItem("pilas:proyecto_serializado");
        let proyecto = this.crear_proyecto_desde_cadena_serializada(proyecto_serializado);
        return this.migraciones.migrar(proyecto);
      }

      if (tipo === "inicial") {
        return this.crear_proyecto_desde_fixture(_proyectoInicial.default);
      } // por omisión, se deja el comportamiento de antes: cuando el usuario
      // vuelve a abrir el editor se le muestra el proyecto anterior o el
      // proyecto inicial con física básica.


      if (localStorage.getItem("pilas:proyecto_serializado")) {
        let proyecto_serializado = localStorage.getItem("pilas:proyecto_serializado");
        let proyecto = this.crear_proyecto_desde_cadena_serializada(proyecto_serializado);
        return this.migraciones.migrar(proyecto);
      } else {
        // caso especial: si el proyecto es muy grande, no se guarda
        // en localstorage sino que se almacena temporalmente en una variable
        // global. Esta variable se elimina al finalizar la carga
        if (window.__tmp__proyecto_serializado) {
          let proyecto = this.crear_proyecto_desde_cadena_serializada(window.__tmp__proyecto_serializado);
          window.__tmp__proyecto_serializado = undefined;
          return this.migraciones.migrar(proyecto);
        } else {
          return this.crear_proyecto_desde_fixture(_proyectoInicial.default);
        }
      }
    }),

    reset(_, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
      }
    },

    crear_proyecto_desde_cadena_serializada(serializado) {
      let proyecto = (0, _stringAJson.default)(serializado);
      let proyectoComoObjetoEmber = (0, _convertirProyectoEnObjetoEmber.default)(proyecto);
      return proyectoComoObjetoEmber;
    },

    cargarProyectoDesdeEjemplo: (0, _emberConcurrency.task)(function* (nombre) {
      let data = yield this.ejemplos.obtener_por_nombre(nombre);
      return data.ejemplo.proyecto;
    }),
    cargarProyectoDesdeHashDelBackend: (0, _emberConcurrency.task)(function* (hash) {
      let proyecto_serializado = yield this.api.obtener_proyecto(hash);
      let proyecto = (0, _stringAJson.default)(proyecto_serializado.serializado);
      let objeto = (0, _convertirProyectoEnObjetoEmber.default)(proyecto.proyecto);
      objeto.set("ver_codigo", proyecto_serializado.ver_codigo);
      return objeto;
    }),
    cargar_proyecto_desde_ruta_archivo: (0, _emberConcurrency.task)(function* (ruta) {
      let proyecto = this.electron.abrir_proyecto_desde_archivo(ruta);
      yield (0, _emberConcurrency.timeout)(100);
      return (0, _convertirProyectoEnObjetoEmber.default)(proyecto);
    }),

    crear_proyecto_desde_fixture(fixture_como_objeto) {
      let fixtureComoString = JSON.stringify(fixture_como_objeto);
      let fixture = JSON.parse(fixtureComoString);
      let proyecto = (0, _convertirProyectoEnObjetoEmber.default)(fixture);
      return this.migraciones.migrar(proyecto);
    },

    realizar_captura_de_pantalla_y_guardar_en_localstorage() {
      let bus = this.bus;
      let servicioProyecto = this.servicioProyecto;

      function captura_realizada(data) {
        var imagen = new Image();
        var canvas = document.createElement("canvas");
        imagen.addEventListener("load", () => {
          var escala = imagen.height / imagen.width;
          canvas.width = 320;
          canvas.height = 320 * escala;
          var contexto = canvas.getContext("2d");
          contexto.drawImage(imagen, 0, 0, imagen.width, imagen.height, 0, 0, canvas.width, canvas.height);
          let imagenEnBase64 = canvas.toDataURL("image/jpeg", 0.5);
          servicioProyecto.guardar_captura_de_pantalla_del_proyecto(imagenEnBase64);
        });
        imagen.src = data.data;
        bus.off("captura_de_pantalla_realizada", captura_realizada);
      }

      this.bus.on("captura_de_pantalla_realizada", captura_realizada); // solicita hacer una captura de pantalla

      this.bus.trigger("capturar_pantalla");
    },

    actions: {
      al_guardar(proyecto) {
        let str = (0, _jsonAString.default)(proyecto);
        this.realizar_captura_de_pantalla_y_guardar_en_localstorage();

        if (this.electron.enElectron) {
          let json = (0, _stringAJson.default)(str);
          this.electron.guardar_proyecto(this.ruta).then(ruta => {
            let proyecto = json;
            this.electron.guardar_proyecto_en_archivo(proyecto, ruta);
          });
        } else {
          this.set("serializado", str);
          saveTextAs(JSON.stringify(proyecto, null, 2), "proyecto.pilas");
          this.servicioProyecto.guardar_proyecto_serializado(str);
        }
      },

      al_crear_proyecto() {
        this.router.transitionTo("iniciar-proyecto");
      },

      al_abrir() {
        if (this.electron.enElectron) {
          this.electron.abrir_proyecto().then(ruta => {
            try {
              this.router.transitionTo("app.abrir_proyecto", ruta);
            } catch (err) {
              console.error(err);
              alert("Error, el archivo está mal formateado: " + err.name);
            }
          });
        } else {
          let input = window.document.getElementById("input-abrir-archivo");

          input.onchange = e => {
            var file = e.target.files[0];

            if (file.name.indexOf(".pilas") === -1) {
              alert(this.intl.t("only.pilas.files"));
            } else {
              const reader = new FileReader();

              reader.onload = event => {
                let proyecto = JSON.parse(event.target.result);
                let serializado = (0, _jsonAString.default)(proyecto);
                this.router.transitionTo("app.abrir_proyecto_serializado", serializado);
              };

              reader.readAsText(file);
            }
          };

          input.click();
        }
      }

    }
  });

  _exports.default = _default;
});