define("pilas-engine/templates/components/pilas-boton-duplicar-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ao6r93Jh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"texto bg-transparent no-outline p0 f7 o-50 pointer glow bn pa0\"],[11,\"data-test\",\"boton-duplicar\"],[9],[0,\"\\n  \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[\"duplicar\"]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"x5\"]]],null,{\"statements\":[[0,\"    x5\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-duplicar-actor.hbs"
    }
  });

  _exports.default = _default;
});