define("pilas-engine/templates/manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vArEBGxS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"vh-100 w-100 dt\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-column vh-100\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"bb b--black-10 pa2\"],[9],[0,\"\\n      \"],[1,[23,\"pilas-boton-regresar\"],false],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"fr\"],[9],[0,\"\\n        \"],[1,[29,\"pilas-boton\",null,[[\"accion\",\"texto\"],[[29,\"action\",[[24,0,[]],\"abrirEnUnaVentanaNueva\"],null],[29,\"t\",[\"open.new.window\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[29,\"pilas-manual\",null,[[\"seccion\",\"cuandoCambiaURL\"],[[25,[\"seccion\"]],[29,\"action\",[[24,0,[]],\"cuandoCambiaURL\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/manual.hbs"
    }
  });

  _exports.default = _default;
});