define("pilas-engine/templates/components/pilas-boton-copiar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RWEjUxSA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"miniatura\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"f7 boton-miniatura\"],[11,\"name\",\"button\"],[9],[0,\"\\n    \"],[1,[29,\"pilas-mini-icono\",null,[[\"icono\"],[\"copiar\"]]],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"cuando_hace_click\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[30,[\"f7 boton verdana link unselectable \",[29,\"if\",[[25,[\"desactivar\"]],\"o-50\",\"pointer\"],null]]]],[12,\"disabled\",[23,\"desactivar\"]],[11,\"name\",\"button\"],[9],[0,\"\\n    \"],[1,[23,\"texto_del_boton\"],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"cuando_hace_click\"]],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"textarea\"],[12,\"id\",[30,[\"input-para-copiar-\",[24,0,[\"id_random\"]]]]],[11,\"class\",\"o-0 absolute\"],[11,\"style\",\"width: 1px\"],[9],[1,[23,\"texto\"],false],[10],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-copiar.hbs"
    }
  });

  _exports.default = _default;
});