define("pilas-engine/components/pilas-propiedad/combo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    valor_actual: Ember.computed("objeto", "propiedad.propiedad", "valor", function () {
      if (this.valor) {
        return this.valor;
      } else {
        return this.objeto.get(this.propiedad.propiedad);
      }
    }),
    actions: {
      al_seleccionar(valor) {
        this.modificarAtributo(this.get("propiedad.propiedad"), valor);
      }

    }
  });

  _exports.default = _default;
});