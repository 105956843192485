define("pilas-engine/templates/api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dg/ohRFp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"vh-100 w-100 dt\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-column vh-100\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"bb b--black-10 pa2\"],[9],[0,\"\\n      \"],[1,[23,\"pilas-boton-regresar\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[23,\"pilas-api\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/api.hbs"
    }
  });

  _exports.default = _default;
});