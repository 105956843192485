define("pilas-engine/components/pilas-boton", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  
  NOTA: la siguiente excepción espera a que se apruebe este pullrequest:
  
    - https://github.com/ember-cli/eslint-plugin-ember/pull/246
    - referencia: https://github.com/ember-cli/eslint-plugin-ember/issues/223
  
  */

  /* eslint ember/no-on-calls-in-components: "off" */
  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    tagName: "",
    truncate: true,
    class: `
    boton
    pa2
    dib
    verdana f6 link pointer
    unselectable
  `,
    demora: 0,
    ejecutando: false,

    didInsertElement() {
      if (this.atajo) {
        this.set("keyboardActivated", true);
      }
    },

    cuando_suelta_tecla: Ember.on((0, _emberKeyboard.keyUp)(), function (event) {
      if (this.atajo) {
        if (this.atajo === (0, _emberKeyboard.getCode)(event)) {
          this.accion();
        }
      }
    }),
    accion: () => {},
    actions: {
      ejecutar_accion() {
        if (this.demora) {
          this.set("ejecutando", true);
          Ember.run.later(() => {
            this.set("ejecutando", false);
            this.accion();
          }, this.demora * 1000);
        } else {
          this.accion();
        }
      }

    }
  });

  _exports.default = _default;
});