define("pilas-engine/routes/app/crear-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    proyecto: Ember.inject.service(),

    afterModel() {
      Ember.run.later(() => {
        this.proyecto.eliminar_proyectos_guardados();
        this.transitionTo("editor");
      }, 1);
    }

  });

  _exports.default = _default;
});