define("pilas-engine/utils/recetas/actor/controlar-el-movimiento-del-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorControlarElMovimientoDelActor;

  function recetasActorControlarElMovimientoDelActor() {
    return {
      titulo: "Керувати рухом актора",
      icono: "receta_pad",
      para: "actor",
      etiquetas: ["mover"],
      codigo: `
      actualizar() {
        let velocidad = 5;

        if (this.pilas.control.izquierda) {
          this.x -= velocidad;
        }

        if (this.pilas.control.derecha) {
          this.x += velocidad;
        }

        if (this.pilas.control.arriba) {
          this.y += velocidad;
        }

        if (this.pilas.control.abajo) {
          this.y -= velocidad;
        }
      }
    `
    };
  }
});