define("pilas-engine/components/pilas-grilla-de-imagenes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    recursos: Ember.inject.service(),
    proyecto: Ember.inject.service(),
    cargando: true,

    didInsertElement() {
      Ember.run.later(this, () => {
        if (!(this.get("isDestroyed") || this.get("isDestroying"))) {
          this.set("cargando", false);
        }
      }, 500);
    },

    actions: {
      incorporar_imagenes_al_proyecto(lista_de_archivos) {
        this.proyecto.incorporar_imagenes_al_proyecto(lista_de_archivos);
      }

    }
  });

  _exports.default = _default;
});