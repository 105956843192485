define("pilas-engine/components/pilas-boton-renombrar-carpeta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: ["dib"],
    actions: {
      cuandoAbre() {
        this.set("nombre", this.carpeta.nombre);
        Ember.run.later(() => {
          let input = document.getElementById("input-renombrar-carpeta");
          input.focus();
          document.execCommand("selectAll");
        }, 1);
      },

      confirmarCambioDeNombre(dd) {
        let nombre = this.nombre.trim();
        dd.actions.close();

        if (nombre.length > 0) {
          this.carpeta.set("nombre", nombre);
        }
      },

      cancelar(dd) {
        dd.actions.close();
      }

    }
  });

  _exports.default = _default;
});