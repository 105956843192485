define("pilas-engine/components/pilas-paginador", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    mostrarRecientesAgrupados: false,
    obtener_etiquetas: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(500);
      return yield this.api.buscarEtiquetas(query);
    }),
    actions: {
      avanzar() {
        this.incrementProperty("pagina");
        this.tarea.perform(this.etiqueta, this.pagina);
      },

      retroceder() {
        this.decrementProperty("pagina");
        this.tarea.perform(this.etiqueta, this.pagina);
      },

      cuando_cambia_etiqueta(etiqueta) {
        this.set("etiqueta", etiqueta);
        this.set("pagina", 1);
        this.tarea.perform(this.etiqueta, this.pagina, this.mostrarRecientesAgrupados);
      },

      cuando_cambia_agrupador(value) {
        this.set("pagina", 1);
        this.set("mostrarRecientesAgrupados", value.target.checked);
        this.tarea.perform(this.etiqueta, this.pagina, this.mostrarRecientesAgrupados);
      }

    }
  });

  _exports.default = _default;
});