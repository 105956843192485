define("pilas-engine/initializers/key-manager-config", ["exports", "pilas-engine/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    const keyManagerConfig = Ember.get(_environment.default, 'keyManagerConfig') || {};
    application.register('main:key-manager-config', keyManagerConfig, {
      instantiate: false
    });
  }

  var _default = {
    name: 'key-manager-config',
    initialize
  };
  _exports.default = _default;
});