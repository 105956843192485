define("pilas-engine/templates/components/pilas-propiedad/separador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QS/YHshQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"texto mt3 mb2 b\"],[9],[1,[29,\"t\",[[25,[\"etiqueta\"]]],null],false],[0,\":\"],[10],[0,\"\\n\\n\"],[15,1],[0,\" \\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-propiedad/separador.hbs"
    }
  });

  _exports.default = _default;
});