define("pilas-engine/components/pilas-propiedad/sensores", ["exports", "pilas-engine/utils/obtener-nombre-sin-repetir"], function (_exports, _obtenerNombreSinRepetir) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sensores: Ember.computed("actor_id", "objeto", "propiedad.propiedad", function () {
      return JSON.parse(JSON.stringify(this.objeto.get(this.propiedad.propiedad)));
    }),
    actions: {
      al_cambiar_valor(nombre, receptor, valor) {
        if (nombre !== "nombre") {
          valor = +valor;
        }

        Ember.set(receptor, nombre, valor);
        this.modificarAtributo(this.get("propiedad.propiedad"), this.sensores);
      },

      crear_un_sensor() {
        let nombres_de_sensores = this.sensores.map(s => s.nombre);
        let nombre = (0, _obtenerNombreSinRepetir.default)(nombres_de_sensores, "sensor");
        let id = Math.random();
        this.sensores.pushObject({
          id: `sensor-${id}`,
          x: 0,
          y: 0,
          ancho: 30,
          alto: 30,
          nombre: nombre
        });
        this.modificarAtributo(this.get("propiedad.propiedad"), this.sensores);
      },

      eliminar_sensor(sensor) {
        this.sensores.removeObject(sensor);
        this.modificarAtributo(this.get("propiedad.propiedad"), this.sensores);
      }

    }
  });

  _exports.default = _default;
});