define("pilas-engine/utils/obtener-contenido-en-base-64", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = obtener_contenido_en_base_64;

  function obtener_contenido_en_base_64(archivo, extension) {
    function corregir_nombre_del_archivo(nombre, extension) {
      let resultado = nombre.toLowerCase();
      extension.split("|").map(extension => {
        resultado = resultado.replace(`.${extension}`, "");
      });
      return resultado;
    }

    return new Ember.RSVP.Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.onload = function () {
        resolve({
          nombre: corregir_nombre_del_archivo(archivo.name, extension),
          contenido: reader.result
        });
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(archivo);
    });
  }
});