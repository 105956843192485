define("pilas-engine/components/pilas-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    url: "./api/index.html"
  });

  _exports.default = _default;
});