define("pilas-engine/templates/components/pilas-experimentos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7n4ONQKU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\\n\"],[7,\"button\"],[9],[0,\"Активувати режим vim\"],[3,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"modoVim\"]]],null],true]],[10],[0,\"\\n\"],[7,\"button\"],[9],[0,\"Деактивувати режим vim\"],[3,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"modoVim\"]]],null],false]],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\"Режим vim увімкнено: \"],[1,[23,\"modoVim\"],false],[10],[0,\"\\n\\n\"],[1,[29,\"monaco-editor\",null,[[\"modoVim\"],[[25,[\"modoVim\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-experimentos.hbs"
    }
  });

  _exports.default = _default;
});