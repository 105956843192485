define("pilas-engine/templates/components/pilas-boton-renombrar-carpeta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rvJdDRrY",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[15,2],[0,\"\\n\\n\"],[4,\"basic-dropdown\",null,[[\"onOpen\"],[[29,\"action\",[[24,0,[]],\"cuandoAbre\"],null]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"trigger\"]],\"expected `dd.trigger` to be a contextual component but found a string. Did you mean `(component dd.trigger)`? ('pilas-engine/templates/components/pilas-boton-renombrar-carpeta.hbs' @ L4:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"texto bg-transparent no-outline p0 f7 o-50 pointer glow bn pa0\"],[9],[1,[29,\"pilas-icono\",null,[[\"icono\"],[\"editar\"]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"content\"]],\"expected `dd.content` to be a contextual component but found a string. Did you mean `(component dd.content)`? ('pilas-engine/templates/components/pilas-boton-renombrar-carpeta.hbs' @ L8:C5) \"],null]],[[\"class\"],[\"slide-fade bg-fondo ba borde shadow-4 pa2 f7\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"ph1\"],[9],[0,\"\\n      \"],[1,[29,\"input\",null,[[\"escape-press\",\"enter\",\"spellcheck\",\"id\",\"type\",\"value\",\"class\",\"placeholder\"],[[29,\"action\",[[24,0,[]],\"cancelar\",[24,1,[]]],null],[29,\"action\",[[24,0,[]],\"confirmarCambioDeNombre\",[24,1,[]]],null],false,\"input-renombrar-carpeta\",\"text\",[25,[\"nombre\"]],\"input w-100 v-top pa1 br2 mb2 f6\",[29,\"t\",[\"name\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-renombrar-carpeta.hbs"
    }
  });

  _exports.default = _default;
});