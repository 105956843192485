define("pilas-engine/components/pilas-boton-webserver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    webserver: Ember.inject.service(),
    iniciando: false,
    activado: false,
    deteniendo: false,
    direccion: "",
    electron: Ember.inject.service(),

    willDestroyElement() {
      if (this.activado) {
        this.send("detener_servidor");
      }
    },

    actions: {
      cuando_activa_webserver() {
        this.set("iniciando", true);
        Ember.run.later(() => {
          this.webserver.iniciar_servidor(8081).then(direccion => {
            this.set("iniciando", false);
            this.set("activado", true);
            this.set("direccion", direccion);
          });
        }, 1000);
      },

      detener_servidor() {
        this.set("deteniendo", true);
        this.webserver.detener_servidor();
        Ember.run.later(() => {
          this.set("activado", false);
          this.set("deteniendo", false);
        }, 1000);
      },

      abrir_en_un_navegador() {
        this.electron.abrir_en_un_navegador(`http://${this.direccion}`);
      }

    }
  });

  _exports.default = _default;
});