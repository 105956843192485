define("pilas-engine/templates/components/pilas-interruptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n3zlm3hy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"button\\n    dib\\n    \",[29,\"if\",[[25,[\"padding\"]],[25,[\"padding\"]],\"pa2\"],null],\"\\n    verdana f7 link pointer\\n    black bg-animate b--black-20\\n    unselectable\\n    m0\\n    relative\\n    \",[29,\"if\",[[25,[\"deshabilitado\"]],\"o-60 disabled\"],null],\"\\n    \",[29,\"if\",[[25,[\"debe_resaltar_el_fondo\"]],\"bg-black-10\"],null],\"\\n    \",[29,\"if\",[[25,[\"rojo\"]],\"white bg-red\"],null],\"\\n    \"]]],[11,\"role\",\"button\"],[12,\"data-test\",[23,\"dataTest\"]],[9],[0,\"\\n\\n\\n\\n\"],[4,\"if\",[[25,[\"icono\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"variable\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[[25,[\"icono_on\"]]]]],false],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[[25,[\"icono_off\"]]]]],false],[15,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"variable\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[\"on\"]]],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"dn texto di-ns\"],[9],[1,[23,\"texto\"],false],[10],[0,\" \"],[7,\"span\"],[11,\"class\",\"di dn-ns\"],[9],[1,[23,\"texto_corto\"],false],[10],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[\"off\"]]],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"dn texto di-ns\"],[9],[1,[23,\"texto\"],false],[10],[0,\" \"],[7,\"span\"],[11,\"class\",\"di dn-ns\"],[9],[1,[23,\"texto_corto\"],false],[10],[15,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"alternar\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-interruptor.hbs"
    }
  });

  _exports.default = _default;
});