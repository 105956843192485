define("pilas-engine/components/pilas-selector-de-grilla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bus: Ember.inject.service(),
    items: null,

    didInsertElement() {
      this.set("items", [{
        etiqueta: "Розмір плитки",
        valor: ""
      }, {
        etiqueta: "1 піксель",
        valor: 1
      }, {
        etiqueta: "2 пікселі",
        valor: 2
      }, {
        etiqueta: "4 пікселі",
        valor: 4
      }, {
        etiqueta: "8 пікселів",
        valor: 8
      }, {
        etiqueta: "16 пікселів",
        valor: 16
      }, {
        etiqueta: "32 пікселі",
        valor: 32
      }, {
        etiqueta: "64 пікселі",
        valor: 64
      }, {
        etiqueta: "128 пікселів",
        valor: 128
      }]);
      this.bus.on("cuando_cambia_grilla", this, "definirGrilla");
    },

    definirGrilla({
      grilla
    }) {
      this.set("grilla", grilla);
    },

    willDestroyElement() {
      this.bus.off("cuando_cambia_grilla", this, "definirGrilla");
    },

    actions: {
      cuandoCambia(grilla) {
        grilla = +grilla;
        this.bus.trigger("cuando_cambia_grilla_desde_el_selector_manual", grilla);
        this.set("grilla", grilla);
      }

    }
  });

  _exports.default = _default;
});