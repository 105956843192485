define("pilas-engine/components/pilas-scroll-to-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",

    didInsertElement() {
      if (this.contenedor) {
        let div = document.getElementById(this.contenedor);
        div.scrollTop = div.scrollHeight;
      }
    }

  });

  _exports.default = _default;
});