define("pilas-engine/templates/components/pilas-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sG28QgiZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"en-electron\",null,null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"role\",\"button\"],[9],[15,1],[3,\"action\",[[24,0,[]],\"abrir\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[23,\"url\"]],[11,\"rel\",\"noopener\"],[11,\"target\",\"_blank\"],[12,\"class\",[29,\"if\",[[25,[\"class\"]],[25,[\"class\"]],\"link blue\"],null]],[9],[15,1],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-link.hbs"
    }
  });

  _exports.default = _default;
});