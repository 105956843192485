define("pilas-engine/components/pilas-boton-login", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mostrarModal: false,
    paso: "login",
    api: Ember.inject.service(),
    sesion: Ember.inject.service(),
    autenticando: false,
    autenticado: false,
    saliendo: false,
    usuario: "",
    email: "",
    contraseña: "",
    etapaLogin: Ember.computed("paso", function () {
      return this.paso === "login";
    }),
    etapaCrearUsuario: Ember.computed("paso", function () {
      return this.paso === "crearUsuario";
    }),
    etapaUsuarioCreadoYAutenticado: Ember.computed("paso", function () {
      return this.paso === "finalizado";
    }),

    hacer_foco_en_el_primer_input() {
      let input = this.element.getElementsByTagName("input")[0];
      input.focus();
      input.select();
    },

    hacer_foco_en_el_primer_boton() {
      let input = this.element.getElementsByTagName("button")[0];
      input.focus();
    },

    limpiar() {
      this.set("usuario", "");
      this.set("email", "");
      this.set("contraseña", "");
      this.set("error", "");
    },

    tareaCrearUsuario: (0, _emberConcurrency.task)(function* () {
      this.set("error", "");
      yield (0, _emberConcurrency.timeout)(500);

      if (!this.usuario || !this.contraseña || !this.email) {
        this.set("error", "Ви повинні заповнити всі три поля");
        return;
      }

      try {
        let respuesta = yield this.api.crearUsuario(this.usuario, this.contraseña, this.email);

        if (respuesta.ok) {
          yield this.sesion.registrarLogin(respuesta.token);
          this.set("paso", "finalizado");
          Ember.run.later(this.hacer_foco_en_el_primer_boton, 1);
        } else {
          this.set("error", respuesta.error);
        }
      } catch (e) {
        this.set("error", e.error);
      }
    }),
    tareaIngresar: (0, _emberConcurrency.task)(function* () {
      this.set("error", "");
      yield (0, _emberConcurrency.timeout)(500);

      if (!this.usuario || !this.contraseña) {
        this.set("error", "Ви повинні заповнити дані");
        return;
      }

      try {
        let respuesta = yield this.api.autenticar(this.usuario, this.contraseña);
        this.send("ocultar"); // la creación de usuario también retorna un token
        // para que se pueda validar la sesión sin tener que
        // hacer otra petición.

        yield this.sesion.registrarLogin(respuesta.token);
        this.limpiar();
      } catch (e) {
        console.log(e);
        this.set("error", "Неправильне ім'я користувача або пароль");
      }
    }),
    tareaCerrarSesion: (0, _emberConcurrency.task)(function* () {
      this.set("saliendo", true);
      yield (0, _emberConcurrency.timeout)(1000);
      this.set("saliendo", false);
      yield this.sesion.cerrarSesion();
    }),
    actions: {
      ocultar() {
        this.set("mostrarModal", false);
      },

      hacer_foco_en(tabindex) {
        let input = this.element.querySelector(`input[tabindex='${tabindex}'`);
        input.focus();
      },

      hacer_click_en(tabindex) {
        let elemento = this.element.querySelector(`[tabindex='${tabindex}'`);
        elemento.click();
      },

      mostrar() {
        this.set("mostrarModal", true);
        this.set("paso", "login");
        Ember.run.later(this, this.hacer_foco_en_el_primer_input, 1);
      },

      irACrearUsuario() {
        this.set("paso", "crearUsuario");
        this.limpiar();
        Ember.run.later(this, this.hacer_foco_en_el_primer_input, 1);
      },

      volver() {
        this.set("paso", "login");
        this.limpiar();
        Ember.run.later(this, this.hacer_foco_en_el_primer_input, 1);
      },

      ingresar() {
        this.tareaIngresar.perform();
      },

      cerrarSesion() {
        this.tareaCerrarSesion.perform();
      },

      crearUsuario() {
        this.tareaCrearUsuario.perform();
      }

    }
  });

  _exports.default = _default;
});