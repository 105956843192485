define("pilas-engine/components/depurador-electron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    router: Ember.inject.service(),
    electron: Ember.inject.service(),
    mostrar: Ember.computed.and("electron.enElectron", "electron.en_desarrollo"),
    livereload: false,

    didInsertElement() {
      this._super(...arguments);

      if (this.mostrar) {
        let electron = requireNode("electron");
        electron.ipcRenderer.on("reload", () => {
          if (this.livereload) {
            window.location.reload();
          } else {
            console.log("Evitando actualizar, livereload desactivado.");
          }
        });
      }
    },

    actions: {
      ir_a_la_seccion_de_pruebas() {
        this.router.transitionTo("pruebas");
      },

      ir_a_la_seccion_principal() {
        this.router.transitionTo("index");
      }

    }
  });

  _exports.default = _default;
});