define("pilas-engine/components/pilas-crear-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    modalVisible: false,
    actores: Ember.inject.service(),
    bus: Ember.inject.service(),
    tarea: Ember.computed.alias("actores.tareaConseguirActores"),

    didInsertElement() {
      this.bus.on("abrir_dialogo_para_crear_actor", this, "abrir_dialogo_desde_señal");
    },

    willDestroyElement() {
      this.bus.off("abrir_dialogo_para_crear_actor", this, "abrir_dialogo_desde_señal");
    },

    abrir_dialogo_desde_señal() {
      this.send("abrirModal");
    },

    actions: {
      ocultar() {
        this.set("modalVisible", false);
      },

      abrirModal() {
        this.set("modalVisible", true);
      }

    }
  });

  _exports.default = _default;
});