define("pilas-engine/components/pilas-boton-eliminar-con-confirmacion", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    modalVisible: false,
    comoCelda: false,
    // TODO: eliminar una vez que las escenas tengan menú contextual, ahora se deja así porque aún se accede a este componente desde las escenas como un ícono simple.
    modalFixed: false,
    contador: false,
    tiempo: 0,
    idDialogo: Ember.computed("tipo", function () {
      return "dialogoEliminar" + this.tipo;
    }),
    lanzar_tarea_si_es_necesario: Ember.observer("modalVisible", function () {
      if (this.modalVisible && this.contador) {
        this.modificar_contador.perform();
      }
    }),
    texto_del_boton: Ember.computed("tiempo", function () {
      if (this.tiempo > 0) {
        return `Так (${this.tiempo})`;
      } else {
        return `Так`;
      }
    }),
    modificar_contador: (0, _emberConcurrency.task)(function* () {
      this.set("tiempo", 3);
      yield (0, _emberConcurrency.timeout)(1000);
      this.set("tiempo", 2);
      yield (0, _emberConcurrency.timeout)(1000);
      this.set("tiempo", 1);
      yield (0, _emberConcurrency.timeout)(1000);
      this.set("tiempo", 0);
    }),
    actions: {
      ocultar() {
        this.set("modalVisible", false);
      },

      mostrar() {
        this.set("modalVisible", true);
      },

      ocultarEjecutandoAccion() {
        this.send("ocultar");
        this.accion();
      }

    }
  });

  _exports.default = _default;
});