define("pilas-engine/helpers/nombre-de-imagen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nombreDeImagen = nombreDeImagen;
  _exports.default = void 0;

  function nombreDeImagen(params) {
    if (params[0]) {
      return params[0].split("/").reverse()[0];
    } else {
      return "";
    }
  }

  var _default = Ember.Helper.helper(nombreDeImagen);

  _exports.default = _default;
});