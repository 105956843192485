define("pilas-engine/components/pilas-boton-copiar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    texto_del_boton: "копіювати",
    desactivar: false,
    id_random: "1",

    didInsertElement() {
      this.set("id_random", Math.random());
    },

    activar_boton() {
      this.set("texto_del_boton", "копіювати");
      this.set("desactivar", false);
    },

    copiar() {
      let input = document.getElementById(`input-para-copiar-${this.id_random}`);
      input.focus();
      input.select();
      document.execCommand("copy");
    },

    actions: {
      cuando_hace_click() {
        this.set("texto_del_boton", "скопійовано...");
        this.set("desactivar", true);
        this.copiar();
        Ember.run.later(this, "activar_boton", 1000);
      }

    }
  });

  _exports.default = _default;
});