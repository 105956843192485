define("pilas-engine/templates/components/pilas-selector-de-grilla", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zVwdKM2E",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"pilas-tooltip\",null,[[\"class\",\"texto\"],[\"right-1 w4\",[29,\"t\",[\"tile.size\"],null]]],{\"statements\":[[0,\"  \"],[7,\"select\"],[11,\"class\",\"w-100 select f7 mt1 verdana\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"cuandoCambia\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"items\"]]],null,{\"statements\":[[0,\"      \"],[7,\"option\"],[12,\"value\",[24,1,[\"valor\"]]],[12,\"selected\",[29,\"eq\",[[25,[\"grilla\"]],[24,1,[\"valor\"]]],null]],[9],[1,[24,1,[\"etiqueta\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-selector-de-grilla.hbs"
    }
  });

  _exports.default = _default;
});