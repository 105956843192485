define("pilas-engine/templates/components/pilas-navegar-ejemplos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LhsRsvYP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"anterior\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"ejemplos.ver\",[25,[\"anterior\"]]],[[\"class\"],[\"ba pa2 button dib br2 verdana f6 link pointer hover-bg-black-10 black bg-animate hover-bg-black-10 b--black-20 unselectable v-top\"]],{\"statements\":[[1,[29,\"t\",[\"previous\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"ejemplos\"],[[\"class\"],[\"disabled o-50 ba pa2 button dib br2 verdana f6 link pointer hover-bg-black-10 black bg-animate hover-bg-black-10 b--black-20 unselectable v-top\"]],{\"statements\":[[1,[29,\"t\",[\"previous\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[25,[\"siguiente\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"ejemplos.ver\",[25,[\"siguiente\"]]],[[\"class\"],[\"ba pa2 button dib br2 verdana f6 link pointer hover-bg-black-10 black bg-animate hover-bg-black-10 b--black-20 unselectable v-top\"]],{\"statements\":[[1,[29,\"t\",[\"next\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"ejemplos\"],[[\"class\"],[\"disabled o-50 ba pa2 button dib br2 verdana f6 link pointer hover-bg-black-10 black bg-animate hover-bg-black-10 b--black-20 unselectable v-top\"]],{\"statements\":[[1,[29,\"t\",[\"next\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-navegar-ejemplos.hbs"
    }
  });

  _exports.default = _default;
});