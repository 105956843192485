define("pilas-engine/templates/components/pilas-zoom-de-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H4Av1AB6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"pt1 pl2\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"class\",\"v-btm w-100\"],[11,\"id\",\"zoom\"],[11,\"min\",\"1\"],[11,\"max\",\"5\"],[12,\"oninput\",[29,\"action\",[[24,0,[]],\"definir_zoom\"],[[\"value\"],[\"target.value\"]]]],[11,\"step\",\"0.5\"],[11,\"value\",\"1\"],[11,\"type\",\"range\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-zoom-de-canvas.hbs"
    }
  });

  _exports.default = _default;
});