define("pilas-engine/templates/components/pilas-esperar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oK6lmYHL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"fixed z-9999 absolute--fill flex bg-black-50 items-center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex1 tc\"],[9],[0,\"\\n    \"],[1,[29,\"pilas-spinner\",null,[[\"s\"],[64]]],false],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-esperar.hbs"
    }
  });

  _exports.default = _default;
});