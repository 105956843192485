define("pilas-engine/utils/recetas/actor/clonar-cuando-hacen-click-sobre-el-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorClonarCuandoHacenClickSobreElActor;

  function recetasActorClonarCuandoHacenClickSobreElActor() {
    return {
      titulo: "Клонувати актора по кліку",
      icono: "receta_clonar",
      para: "actor",
      etiquetas: ["click", "clonar"],
      codigo: `
      cuando_hace_click(x: number, y: number, evento) {
        let clonacion = this.pilas.clonar(this.nombre);

        // Клона можна створити в іншому місці 
        // від місця з оригіналом, вказавши нові координати
        clonacion.x = this.x + 25;
        clonacion.y = this.y;
      }
    `
    };
  }
});