define("pilas-engine/templates/components/pilas-crear-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wOkNoPyc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[1,[29,\"pilas-boton\",null,[[\"icono\",\"desactivado\",\"accion\",\"texto\"],[[25,[\"icono\"]],[25,[\"desactivado\"]],[29,\"action\",[[24,0,[]],\"abrirModal\"],null],[29,\"t\",[\"create.actor\"],null]]]],false],[0,\"\\n\\n\\n\"],[4,\"pilas-modal\",null,[[\"alCerrar\",\"visible\",\"id\",\"titulo\"],[[29,\"action\",[[24,0,[]],\"ocultar\"],null],[25,[\"modalVisible\"]],\"dialogoCrearActor\",[29,\"t\",[\"create.actor\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"tarea\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\n      \"],[1,[23,\"pilas-celda-de-actor-skeleton\"],false],[0,\"\\n      \"],[1,[23,\"pilas-celda-de-actor-skeleton\"],false],[0,\"\\n      \"],[1,[23,\"pilas-celda-de-actor-skeleton\"],false],[0,\"\\n      \"],[1,[23,\"pilas-celda-de-actor-skeleton\"],false],[0,\"\\n      \"],[1,[23,\"pilas-celda-de-actor-skeleton\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[1,[29,\"pilas-grilla-de-actores\",null,[[\"actores\",\"cuandoQuiereCrearActor\"],[[25,[\"tarea\",\"last\",\"value\",\"actores\"]],[25,[\"cuandoQuiereCrearActor\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-crear-actor.hbs"
    }
  });

  _exports.default = _default;
});