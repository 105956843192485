define("pilas-engine/components/pilas-boton-configuracion-del-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      disminuir() {
        this.set("tamano", Math.max(10, this.tamano - 1));
      },

      aumentar() {
        this.set("tamano", Math.min(24, this.tamano + 1));
      },

      restaurar() {
        this.set("tamano", 14);
      }

    }
  });

  _exports.default = _default;
});