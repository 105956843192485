define("pilas-engine/components/pilas-boton-miniatura-pulsable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    class: "dib boton-miniatura-pulsable f7",
    actions: {
      pulsar() {
        if (this.accion) {
          this.accion();
        }
      }

    }
  });

  _exports.default = _default;
});