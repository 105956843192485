define("pilas-engine/components/pilas-navegar-ejemplos", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["unselectable"],
    ejemplos: Ember.inject.service(),
    anterior: null,
    siguiente: null,
    cuando_cambia_ejemplo: Ember.observer("nombre", function () {
      this.iniciar.perform();
    }),

    didInsertElement() {
      this.iniciar.perform();
    },

    iniciar: (0, _emberConcurrency.task)(function* () {
      yield this.ejemplos.obtener();
      this.set("anterior", this.ejemplos.obtener_anterior(this.nombre));
      this.set("siguiente", this.ejemplos.obtener_siguiente(this.nombre));
    }).drop()
  });

  _exports.default = _default;
});