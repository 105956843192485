define("pilas-engine/templates/components/pilas-boton-regresar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EDyd+e88",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"pilas-boton\",null,[[\"extraClass\",\"icono\",\"accion\",\"texto\"],[\"test-regresar\",\"volver\",[29,\"action\",[[24,0,[]],\"regresar\"],null],\"\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"mostrar\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"pilas-modal\",null,[[\"reducido\",\"alCerrar\",\"visible\",\"id\",\"titulo\"],[true,[29,\"action\",[[24,0,[]],\"ocultar\"],null],true,\"dialogo_confirmar\",\"¿Quieres salir sin guardar?\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"f6\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[1,[29,\"t\",[\"exit.title\"],null],false],[10],[0,\"\\n      \"],[7,\"p\"],[9],[1,[29,\"t\",[\"exit.question\"],null],false],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"tr pt2\"],[9],[0,\"\\n        \"],[1,[29,\"pilas-boton\",null,[[\"accion\",\"texto\"],[[29,\"action\",[[24,0,[]],\"confirmar_regreso\"],null],[29,\"t\",[\"exit.confirm\"],null]]]],false],[0,\"\\n        \"],[1,[29,\"pilas-boton\",null,[[\"accion\",\"texto\"],[[29,\"action\",[[24,0,[]],\"ocultar\"],null],[29,\"t\",[\"exit.cancel\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-regresar.hbs"
    }
  });

  _exports.default = _default;
});