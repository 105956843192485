define("pilas-engine/components/pilas-boton-regresar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: "",
    mostrar: false,
    actions: {
      regresar() {
        if (this.confirmar) {
          this.set("mostrar", true);
        } else {
          this.router.transitionTo("index");
        }
      },

      ocultar() {
        this.set("mostrar", false);
      },

      confirmar_regreso() {
        this.router.transitionTo("app.editor.abandonar-proyecto");
      }

    }
  });

  _exports.default = _default;
});