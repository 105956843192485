define("pilas-engine/templates/components/depurador-electron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fVt8nCew",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"],[4,\"if\",[[25,[\"mostrar\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"absolute bottom-0 bt b--silver left-0 right-0 bg-white\"],[9],[0,\"\\n\"],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fc fr v-mid\"],[9],[0,\"\\n      \"],[1,[29,\"pilas-interruptor\",null,[[\"texto\",\"variable\"],[\"livereload\",[25,[\"livereload\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/depurador-electron.hbs"
    }
  });

  _exports.default = _default;
});