define("pilas-engine/utils/recetas/actor/controlar-al-actor-como-si-fuera-un-automovil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorControlarAlActorComoSiFueraUnAutomovil;

  function recetasActorControlarAlActorComoSiFueraUnAutomovil() {
    return {
      titulo: "Керувати актором як автомобілем",
      icono: "receta_pad",
      para: "actor",
      etiquetas: ["mover", "control"],
      codigo: `
            actualizar() {
               let velocidad = 5;
               let velocidad_para_doblar = 5;

               if (this.pilas.control.arriba) {
                   this.avanzar(this.rotacion, velocidad)
               }

               if (this.pilas.control.izquierda) {
                   this.rotacion += velocidad_para_doblar;
               }

               if (this.pilas.control.derecha) {
                   this.rotacion -= velocidad_para_doblar;
               }
           }
        `
    };
  }
});