define("pilas-engine/templates/components/pilas-celda-de-actor-skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xy4ugiAW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dib w4 bg-light-gray br2 pa2 m2 mb1\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-column\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex1\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"h3 w3\"],[9],[0,\"\\n         \\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"f6 tc mt2 h1\"],[9],[0,\"\\n      \"],[15,1],[0,\"\\n       \\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-celda-de-actor-skeleton.hbs"
    }
  });

  _exports.default = _default;
});