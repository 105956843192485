define("pilas-engine/templates/components/pilas-input-numero-con-deslizador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Iaw91ZI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[30,[\"texto pa2 \",[23,\"claseEtiqueta\"],\" tc data-etiqueta cursor-resize-on-hover bb-dashed-on-hover\"]]],[9],[0,\"\\n  \"],[1,[23,\"identificador\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[23,\"claseInput\"]],[9],[0,\"\\n  \"],[1,[29,\"input-numero\",null,[[\"min\",\"max\",\"valor\",\"cuandoCambia\"],[[25,[\"min\"]],[25,[\"max\"]],[25,[\"valor\"]],[25,[\"cuandoCambia\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-input-numero-con-deslizador.hbs"
    }
  });

  _exports.default = _default;
});