define("pilas-engine/components/pilas-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    servicioProyecto: Ember.inject.service("proyecto"),
    proyecto: null,
    editor: null,

    didInsertElement() {
      this.servicioProyecto.vincular(this.proyecto, this.editor);
    }

  });

  _exports.default = _default;
});