define("pilas-engine/helpers/helper-detener-musica", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detenerMusica = detenerMusica;
  _exports.default = void 0;

  function detenerMusica() {
    return `this.detener_musica();`;
  }

  var _default = Ember.Helper.helper(detenerMusica);

  _exports.default = _default;
});