define("pilas-engine/templates/components/pilas-propiedad/imagen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8TRYBgs2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex pv1\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-50\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"dib texto\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[[25,[\"etiqueta\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"dib texto w-50 v-top pointer flex\"],[11,\"role\",\"button\"],[9],[0,\"\\n\"],[4,\"pilas-tooltip\",null,[[\"class\",\"texto\"],[\"right-1 bottom-2\",[29,\"get\",[[25,[\"objeto\"]],[25,[\"propiedad\",\"propiedad\"]]],null]]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n        \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[\"imagen\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"truncate pl1\"],[11,\"style\",\"width: 95px;\"],[9],[0,\"\\n      \"],[1,[29,\"nombre-de-imagen\",[[29,\"get\",[[25,[\"objeto\"]],[25,[\"propiedad\",\"propiedad\"]]],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"mostrar\"]],[10],[0,\"\\n\\n\"],[4,\"pilas-modal\",null,[[\"alCerrar\",\"visible\",\"id\",\"titulo\"],[[29,\"action\",[[24,0,[]],\"ocultar\"],null],[25,[\"mostrar\"]],\"dialogo_cambiar_imagen\",[29,\"t\",[\"change.image\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n      \"],[1,[29,\"pilas-grilla-de-imagenes\",null,[[\"filtro\",\"cuando_selecciona\"],[[25,[\"filtroPropuesto\"]],[29,\"action\",[[24,0,[]],\"cuando_selecciona_imagen\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-propiedad/imagen.hbs"
    }
  });

  _exports.default = _default;
});