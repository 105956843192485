define("pilas-engine/templates/components/input-numero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4nJDk5gZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\"],[11,\"class\",\"input no-spinners w-100 v-top pa1 br2\"],[12,\"min\",[23,\"min\"]],[12,\"max\",[23,\"max\"]],[12,\"value\",[23,\"valor\"]],[12,\"onChange\",[29,\"action\",[[24,0,[]],\"onChange\"],null]],[11,\"type\",\"number\"],[9],[10],[0,\"\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/input-numero.hbs"
    }
  });

  _exports.default = _default;
});