define("pilas-engine/components/pilas-item-de-proyecto-en-el-explorador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    api: Ember.inject.service(),
    actions: {
      borrar() {
        var respuesta = confirm("¿Realmente quires borrar este juego?");

        if (respuesta) {
          this.set("proyecto.eliminado", true);
          this.api.eliminar_proyecto(this.proyecto.hash);
        }
      }

    }
  });

  _exports.default = _default;
});