define("pilas-engine/components/pilas-icono", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    classNames: ["sprite"],
    classNameBindings: ["icono", "conSeparacion:mr2", "class"],
    conSeparacion: false
  });

  _exports.default = _default;
});