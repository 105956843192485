define("pilas-engine/templates/components/pilas-skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t1/lq8lo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dib bg-skeleton mw-100\"],[12,\"style\",[23,\"style\"]],[9],[15,1],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-skeleton.hbs"
    }
  });

  _exports.default = _default;
});