define("pilas-engine/templates/components/pilas-header-exportacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "52W+J4pV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"flex verdana unselectable theme-oscuro bg-fondo items-center flex1\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex1 pv2 ph2\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"model\",\"ver_codigo\"]]],null,{\"statements\":[[4,\"pilas-boton-miniatura-pulsable\",null,[[\"accion\"],[[29,\"action\",[[24,0,[]],\"abrir_en_el_editor\"],null]]],{\"statements\":[[0,\"        Переглянути код у редакторі\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex1\"],[9],[0,\" \"],[10],[0,\"\\n\\n\"],[4,\"pilas-boton-miniatura-pulsable\",null,[[\"accion\"],[[29,\"action\",[[24,0,[]],\"pantalla_completa\"],null]]],{\"statements\":[[0,\"      Увесь екран\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"style\",\"margin-top:-10px; padding-right: 0.5em\"],[9],[0,\"\\n    \"],[1,[29,\"pilas-selector-de-modo-zoom\",null,[[\"modoZoom\"],[[25,[\"model\",\"modoZoom\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-header-exportacion.hbs"
    }
  });

  _exports.default = _default;
});