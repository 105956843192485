define("pilas-engine/components/pilas-panel-de-escenas-en-modo-pausa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    bus: Ember.inject.service(),
    actores: null,
    actorSeleccionado: null,

    didInsertElement() {
      this.bus.on("aplica_el_cambio_de_posicion_en_el_modo_pausa", this, "actualizar");
    },

    willDestroyElement() {
      this.bus.off("aplica_el_cambio_de_posicion_en_el_modo_pausa", this, "actualizar");
    },

    actualizar(data) {
      Ember.run.debounce(this, this.realizarActualizacion, data, 500);
    },

    realizarActualizacion(data) {
      if (!(this.get("isDestroyed") || this.get("isDestroying"))) {
        this.set("actores", data.foto.actores);
      }
    },

    actions: {
      seleccionaActor(actor) {
        this.set("actorSeleccionado", actor.nombre);
        this.bus.trigger("selecciona_un_actor_en_modo_pausa", actor);
      },

      seleccionarEscena() {
        this.set("actorSeleccionado", null);
        this.bus.trigger("selecciona_la_escena_completa_en_modo_pausa");
      }

    }
  });

  _exports.default = _default;
});