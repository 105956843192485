define("pilas-engine/templates/components/pilas-panel-de-escenas/contenido-de-la-escena", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FG/GCLpn",
    "block": "{\"symbols\":[\"nodo\"],\"statements\":[[4,\"each\",[[25,[\"escena_como_nodos\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[24,1,[\"tipo\"]],\"carpeta\"],null]],null,{\"statements\":[[0,\"\\n    \"],[1,[29,\"pilas-panel-de-escenas/item-carpeta\",null,[[\"actores\",\"carpeta\",\"escena\",\"seleccion\",\"escenas\",\"ultimaEscenaSeleccionada\",\"cuandoSelecciona\"],[[24,1,[\"actores\"]],[24,1,[\"carpeta\"]],[25,[\"escena\"]],[25,[\"seleccion\"]],[25,[\"escenas\"]],[25,[\"ultimaEscenaSeleccionada\"]],[25,[\"cuandoSelecciona\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[24,1,[\"tipo\"]],\"actor\"],null]],null,{\"statements\":[[0,\"\\n    \"],[1,[29,\"pilas-panel-de-escenas/item-actor\",null,[[\"actor\",\"seleccion\",\"escenas\",\"ultimaEscenaSeleccionada\",\"cuandoSelecciona\"],[[24,1,[\"actor\"]],[25,[\"seleccion\"]],[25,[\"escenas\"]],[25,[\"ultimaEscenaSeleccionada\"]],[25,[\"cuandoSelecciona\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"ba b--red\"],[9],[0,\"Невідомий тип вузла: \"],[1,[24,1,[\"tipo\"]],false],[10],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-black-20 br2 ma1 o-50 pa2 texto\"],[9],[0,\"\\n    Без акторів\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-panel-de-escenas/contenido-de-la-escena.hbs"
    }
  });

  _exports.default = _default;
});