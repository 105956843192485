define("pilas-engine/templates/components/pilas-boton-importar-sonido", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o2D7bAa+",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"pilas-boton\",null,[[\"accion\",\"texto\",\"icono\"],[[29,\"action\",[[24,0,[]],\"seleccionar_sonido\"],null],[29,\"t\",[\"audio.load.sound\"],null],\"mas\"]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"dn\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"multiple\",\"true\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"procesar_sonido_seleccionado\"],null]],[11,\"accept\",\"audio/mp3\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-importar-sonido.hbs"
    }
  });

  _exports.default = _default;
});