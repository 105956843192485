define("pilas-engine/templates/components/pilas-celda-de-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z+sPpM7h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"test-celda-actor\",\"\"],[12,\"class\",[30,[\"\\n    celda-actor \\n    ba pa2 button\\n    br2\\n    verdana f6 link pointer\\n    black bg-animate b--black-20\\n    unselectable\\n    v-top\\n    mb1\\n\\n    \",[29,\"if\",[[25,[\"debeOcultarPorFiltro\"]],\"dn\",\"dib\"],null],\"\\n\\n    \",[29,\"if\",[[29,\"eq\",[[25,[\"actor\"]],[25,[\"actor_seleccionado\"]]],null],\"bg-black-20\",\"hover-bg-black-10\"],null],\"\\n\"]]],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-column h-100 w-100\"],[9],[0,\"\\n\\n    \"],[15,1],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"flex1 tc w-100 h3\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[30,[\"sprite grilla_actor_\",[25,[\"actor\",\"nombre\"]],\" mw-100 mh-100\"]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"f6 tc texto\"],[9],[0,\"\\n      \"],[1,[23,\"nombreSeparadoEnPalabras\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[25,[\"cuandoQuiereCrearActor\"]],[25,[\"actor\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-celda-de-actor.hbs"
    }
  });

  _exports.default = _default;
});