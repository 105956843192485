define("pilas-engine/templates/components/pilas-boton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EJMjDsV7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[11,\"role\",\"button\"],[12,\"data-test\",[23,\"dataTest\"]],[12,\"class\",[30,[[23,\"class\"],\" \",[23,\"extraClass\"],\" \",[29,\"if\",[[25,[\"truncate\"]],\"truncate\",\"\"],null],\" \",[29,\"if\",[[25,[\"ejecutando\"]],\"disabled o-50\"],null],\" \",[29,\"if\",[[25,[\"desactivado\"]],\"disabled o-50\"],null]]]],[12,\"id\",[23,\"id\"]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"ejecutando\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,\"pilas-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"icono\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[[25,[\"icono\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"responsive\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"dn dib-l\"],[9],[1,[23,\"texto\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\"],[9],[1,[23,\"texto\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[9],[1,[23,\"texto\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"ejecutar_accion\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton.hbs"
    }
  });

  _exports.default = _default;
});