define("pilas-engine/utils/recetas/escena/crear-actor-en-la-posicion-en-donde-se-hace-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasEscenaCrearActorEnLaPosicionEnDondeSeHaceClick;

  function recetasEscenaCrearActorEnLaPosicionEnDondeSeHaceClick() {
    return {
      titulo: "Створити актора в точці, де відбувся клік",
      icono: "receta_click",
      para: "escena",
      etiquetas: ["mouse", "cursor", "crear", "actor", "click"],
      codigo: `
          cuando_hace_click(x: number, y: number, evento) {
            let actor = this.pilas.actores.pelota();
            actor.x = x;
            actor.y = y;
        }
      `
    };
  }
});