define("pilas-engine/utils/recetas/actor/crear-estado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorCuandoColisionaEliminarAlOtroActor;

  function recetasActorCuandoColisionaEliminarAlOtroActor() {
    return {
      titulo: "Створити сцену автоматично",
      icono: "receta_automata",
      para: "actor",
      etiquetas: ["estado", "autómata"],
      componente: "pilas-receta-crear-estado"
    };
  }
});