define("pilas-engine/templates/components/pilas-botones-para-deshacer-y-rehacer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SqYQnyNS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"f7 texto \",[29,\"if\",[[25,[\"estado\",\"puedeEjecutar\"]],\"flex\",\"dn\"],null]]]],[9],[0,\"\\n\"],[4,\"pilas-tooltip\",null,[[\"class\",\"texto\"],[\"right-1 w4\",[29,\"concat\",[[29,\"concat\",[[29,\"t\",[\"undo\"],null],\" \"],null],[25,[\"memento\",\"ultima_accion\"]]],null]]],{\"statements\":[[4,\"pilas-boton-miniatura-pulsable\",null,[[\"desactivado\",\"class\",\"accion\"],[[29,\"not\",[[25,[\"memento\",\"puede_deshacer\"]]],null],\"f7 texto pr2\",[25,[\"deshacer\"]]]],{\"statements\":[[0,\"      \"],[1,[29,\"pilas-mini-icono\",null,[[\"icono\"],[\"undo\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-botones-para-deshacer-y-rehacer.hbs"
    }
  });

  _exports.default = _default;
});