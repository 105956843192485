define("pilas-engine/templates/components/pilas-interprete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Kaputcg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"h4 menlo ba borde bg-fondo-editor \",[29,\"if\",[[25,[\"mostrar\"]],\"flex flex-column\",\"dn\"],null]]]],[9],[0,\"\\n\\n  \"],[1,[29,\"pilas-contenido-de-log\",null,[[\"estilo\",\"log\"],[[25,[\"estilo\"]],[25,[\"log\"]]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-100 flex flex-row bt borde\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"style\",[23,\"estilo\"]],[11,\"class\",\"dib white pa1 unselectable flex items-center pl2\"],[9],[0,\"\\n      ›\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[12,\"style\",[23,\"estilo\"]],[12,\"class\",[29,\"if\",[[25,[\"habilitado\"]],\"flex1 flex\",\"dn\"],null]],[9],[0,\"\\n      \"],[1,[29,\"input\",null,[[\"id\",\"keyDown\",\"value\",\"spellcheck\",\"class\",\"enter\"],[\"input\",[29,\"action\",[[24,0,[]],\"pulsaTecla\"],null],[25,[\"valor\"]],false,\"menlo pa1 bn no-outline white bg-fondo-editor flex1\",[29,\"action\",[[24,0,[]],\"cuandoPulsaEnter\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"unless\",[[25,[\"habilitado\"]]],null,{\"statements\":[[0,\"      \"],[7,\"input\"],[12,\"style\",[23,\"estilo\"]],[11,\"spellcheck\",\"false\"],[12,\"placeholder\",[29,\"t\",[\"interpreter.help\"],null]],[11,\"disabled\",\"disabled\"],[11,\"class\",\"flex1 menlo pa1 bn no-outline white bg-fondo-editor\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-interprete.hbs"
    }
  });

  _exports.default = _default;
});