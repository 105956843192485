define("pilas-engine/components/pilas-editor-panel-de-codigo", ["exports", "pilas-engine/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    bus: Ember.inject.service(),
    activarBloquesDesdeConfiguracion: _environment.default.activarBloques,
    canvasMaximizado: Ember.computed("panelMaximizado", function () {
      return this.get("panelMaximizado") == "canvas" || this.get("panelMaximizado") == "canvas-desde-el-editor";
    }),
    claseDelPanel: Ember.computed("mostrarEditor", "canvasMaximizado", function () {
      if (this.canvasMaximizado) {
        return "dn";
      }

      if (this.mostrarEditor) {
        return "flex flex-column";
      } else {
        // Se añaden estas clases en lugar de "dn" porque monaco necesita un espacio
        // en pantalla para inicializarse correctamente.
        return "fixed w1 h1 no-pointer-events o-0";
      }
    }),
    claseDelPanelColapsado: Ember.computed("mostrarEditor", "canvasMaximizado", function () {
      if (this.canvasMaximizado) {
        return "dn";
      }

      if (this.mostrarEditor) {
        return "dn";
      } else {
        return "flex flex-column w-panel-colapsado";
      }
    }),
    actions: {
      alternar(propiedad) {
        this.toggleProperty(propiedad);
      },

      alCambiarMaximizado(valor) {
        this.bus.trigger("hacerFocoEnElEditor", {});

        if (valor) {
          this.set("panelMaximizado", "editor");
        } else {
          this.set("panelMaximizado", null);
        }
      },

      actualizarEstadoDeErrores(errores) {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.set("tieneErrores", errores.length > 0);
        this.set("errores", errores);
      },

      mostrarCodigo() {
        this.set("mostrarBloques", false);
        this.set("mostrarLoader", true);
        Ember.run.later(this, () => {
          this.set("mostrarLoader", false);
        }, 1000);
      },

      mostrarBloques() {
        this.set("mostrarBloques", true);
        this.set("mostrarLoader", true);
        Ember.run.later(this, () => {
          this.set("mostrarLoader", false);
        }, 1000);
      }

    }
  });

  _exports.default = _default;
});