define("pilas-engine/components/pilas-contenido-de-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bus: Ember.inject.service(),
    classNames: ["flex1", "overflow-y-auto"],

    didInsertElement() {
      this.bus.on("se_actualiza_el_log", this, "realizarScroll");
    },

    willDestroyElement() {
      this.bus.off("se_actualiza_el_log", this, "realizarScroll");
    },

    realizarScroll() {
      Ember.run.later(() => {
        if (this.element) {
          let elemento = this.element;

          if (elemento && elemento.scroll) {
            elemento.scroll(0, elemento.scrollHeight);
          }
        }
      }, 10);
    }

  });

  _exports.default = _default;
});