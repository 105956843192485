define("pilas-engine/components/pilas-panel-de-escenas/item-escena", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["clase"],
    clase: "",
    proyecto: Ember.inject.service(),

    dragLeave(event) {
      event.preventDefault();
      this.set("clase", "");
    },

    dragOver(event) {
      event.preventDefault();
      this.set("clase", "bg-blue");
    },

    drop(event) {
      this.set("clase", "");
      let actor_id = JSON.parse(event.dataTransfer.getData("text/data")).id;
      this.proyecto.agregar_actor_a_la_escena(actor_id, this.ultimaEscenaSeleccionada, this.escena.id);
      return false;
    }

  });

  _exports.default = _default;
});