define("pilas-engine/templates/components/pilas-boton-principal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V9cRi2KO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"queryParams\"]]],null,{\"statements\":[[4,\"link-to\",[[25,[\"ruta\"]],[25,[\"queryParams\"]]],[[\"class\",\"id\"],[[25,[\"class\"]],[25,[\"id\"]]]],{\"statements\":[[0,\"    \"],[1,[29,\"pilas-icono\",null,[[\"icono\",\"conSeparacion\"],[[25,[\"icono\"]],true]]],false],[0,\"\\n\\n    \"],[1,[23,\"texto\"],false],[0,\"\\n    \"],[15,1],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[25,[\"ruta\"]]],[[\"class\",\"id\"],[[25,[\"class\"]],[25,[\"id\"]]]],{\"statements\":[[0,\"    \"],[1,[29,\"pilas-icono\",null,[[\"icono\",\"conSeparacion\"],[[25,[\"icono\"]],true]]],false],[0,\"\\n\\n    \"],[1,[23,\"texto\"],false],[0,\"\\n    \"],[15,1],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-principal.hbs"
    }
  });

  _exports.default = _default;
});