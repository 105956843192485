define("pilas-engine/utils/recetas/actor/cambiar-la-posicion-del-actor-al-azar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorCambiarLaPosicionDelActorAlAzar;

  function recetasActorCambiarLaPosicionDelActorAlAzar() {
    return {
      titulo: "Змінити на початку координати актора випадковим чином",
      icono: "receta_azar",
      para: "actor",
      etiquetas: ["azar"],
      codigo: `
          iniciar() {
            this.x = this.pilas.azar(-200, 200);
            this.y = this.pilas.azar(-230, 230);
          }
      `
    };
  }
});