define("pilas-engine/components/pilas-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["dib", "dim", "pointer", "blue"],
    electron: Ember.inject.service(),
    url: null,
    actions: {
      abrir() {
        this.electron.abrir_en_un_navegador(this.url);
      }

    }
  });

  _exports.default = _default;
});