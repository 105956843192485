define("pilas-engine/templates/components/pilas-ordenable-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Szyj0Mjq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"id\",[23,\"identificador\"]],[12,\"class\",[30,[\"w-max-content \",[29,\"if\",[[25,[\"iniciando\"]],\"no-pointer-events o-50\"],null]]]],[9],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-ordenable-horizontal.hbs"
    }
  });

  _exports.default = _default;
});