define("pilas-engine/components/pilas-propiedad/cadena", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      al_cambiar(valor) {
        this.modificarAtributo(this.get("propiedad.propiedad"), valor);
      },

      comenzar_a_editar() {
        this.set("editando", true);
        Ember.run.later(() => {
          this.element.querySelector("input").focus();
          this.element.querySelector("input").select();
        });
      },

      cuando_pierde_foco() {
        this.set("editando", false);
      },

      cuando_suelta_tecla(evento) {
        if (evento.keyCode === 13) {
          this.set("editando", false);
        }
      }

    }
  });

  _exports.default = _default;
});