define("pilas-engine/components/pilas-boton-animaciones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    modalVisible: false,
    bus: Ember.inject.service(),
    actions: {
      ocultar() {
        this.set("modalVisible", false);
        this.bus.trigger("cierra_dialogo_de_animaciones");
      },

      abrirModal() {
        this.set("modalVisible", true);
      }

    }
  });

  _exports.default = _default;
});