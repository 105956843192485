define("pilas-engine/components/pilas-boton-duplicar-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: "dib",
    keyManager: Ember.inject.service(),
    x5: false,
    mouseDown: function (e) {
      if (e.metaKey) {
        this.accionx5();
      } else {
        this.accion();
      }
    },

    didInsertElement() {
      const cuandoPulsaTecla = this.keyManager.addMacro({
        callback: () => {
          this.set("x5", true);
        },
        executionKey: "Meta",
        priority: 10,
        keyEvent: "keydown"
      });
      this.set("cuandoPulsaTecla", cuandoPulsaTecla);
      const cuandoSueltaTecla = this.keyManager.addMacro({
        callback: () => {
          this.set("x5", false);
        },
        executionKey: "Meta",
        priority: 10,
        keyEvent: "keyup"
      });
      this.set("cuandoSueltaTecla", cuandoSueltaTecla);
    },

    willDestroyElement() {
      this.keyManager.removeMacro(this.cuandoPulsaTecla);
      this.keyManager.removeMacro(this.cuandoSueltaTecla);
    }

  });

  _exports.default = _default;
});