define("pilas-engine/utils/recetas/actor/mover-al-actor-a-la-izquierda", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorMoverAlActorALaIzquierda;

  function recetasActorMoverAlActorALaIzquierda() {
    return {
      titulo: "Перемістити актора вліво та видалити, якщо він покине екран",
      icono: "receta_mover_izquierda",
      para: "actor",
      etiquetas: ["mover"],
      codigo: `
        actualizar() {
          let velocidad = 5;
          this.x -= velocidad;

          if (this.x < -250) {
            this.eliminar();
          }
        }
      `
    };
  }
});