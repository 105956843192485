define("pilas-engine/templates/components/item-de-la-lista-de-ejemplos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w7uUzaVs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"ejemplos.ver\",[25,[\"ejemplo\",\"nombre\"]]],[[\"class\"],[[25,[\"clases\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex flex-column\"],[11,\"style\",\"width: 180px\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"style\",\"width: 180px; height: 140px;\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[30,[\"sprite-ejemplos capturas-\",[25,[\"ejemplo\",\"nombre\"]],\" b--gray ba\"]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"bg-black-80 pa1 white tc lato f7 bl bb br b--black-90 truncate\"],[9],[0,\"\\n      \"],[1,[25,[\"ejemplo\",\"nombre\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"pilas-ejemplos-es-nuevo\",null,[[\"ejemplo\"],[[25,[\"ejemplo\"]]]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"absolute top-0 right-0 pa1 ma1 br2 black lato f7 bg-yellow ttu\"],[9],[1,[29,\"t\",[\"new\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/item-de-la-lista-de-ejemplos.hbs"
    }
  });

  _exports.default = _default;
});