define("pilas-engine/components/pilas-selector-de-idioma", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    seleccion: 'uk',
    intl: Ember.inject.service(),

    didInsertElement() {
      this.set("seleccion", this.intl.locale[0]);
      this.set("idiomas", ["uk", "es", "en", "zh"]);
    },

    actions: {
      definirIdioma(idioma) {
        this.set("seleccion", idioma);
        this.intl.setLocale(idioma);
      }

    }
  });

  _exports.default = _default;
});