define("pilas-engine/utils/recetas/escena/crear-10-actores-en-posiciones-al-azar-cuando-comienza", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasEscenaCrear10ActoresEnPosicionesAlAzarCuandoComienza;

  function recetasEscenaCrear10ActoresEnPosicionesAlAzarCuandoComienza() {
    return {
      titulo: "Створити 10 акторів у випадкових позиціях на сцені",
      icono: "receta_azar",
      para: "escena",
      etiquetas: ["azar", "crear"],
      codigo: `
        iniciar() {
          for (let i=0; i<10; i++) {
            let actor: Actor = this.pilas.actores.aceituna();
            actor.x = this.pilas.azar(-200, 200);
            actor.y = this.pilas.azar(-230, 230);
          }
        }
    `
    };
  }
});