define("pilas-engine/components/pilas-receta-crear-estado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    modal_visible: false,
    nombreSugerido: "",
    invalido: Ember.computed("errores.length", function () {
      return this.get("errores.length") > 0;
    }),
    errores: Ember.computed("nombreSugerido", function () {
      if (this.nombreSugerido.trim().length == 0) {
        return ["Вкажіть ім'я"];
      }

      if (!/^[a-zA-Z_][0-9a-zA-Z_]+$/.test(this.nombreSugerido)) {
        return ["Треба просто написати просте слово"];
      }
    }),

    mover_scroll() {
      let input = document.getElementById("input-nombre-del-estado");
      let offset = input.closest("#receta-crear-estado").offsetTop;
      input.closest(".overflow-y-auto").scrollTo({
        top: offset - 64,
        left: 0,
        behavior: "smooth"
      });
    },

    hacer_foco() {
      let input = document.getElementById("input-nombre-del-estado");
      input.focus();
    },

    actions: {
      seleccionar() {
        this.set("modal_visible", true);
        Ember.run.later(this, "mover_scroll", 1);
        Ember.run.later(this, "hacer_foco", 2);
      },

      ocultar() {
        this.dd.actions.close();
      },

      confirmar() {
        let estado = this.nombreSugerido;
        this.set("modal_visible", false);
        this.receta.codigo = `// Estado: ${estado}

        ${estado}_iniciar() {

        }

        ${estado}_actualizar() {

        }

        ${estado}_cuando_finaliza_animacion(nombre: string) {

        }

        ${estado}_cada_segundo(segundos_transcurridos: number) {

        }

        ${estado}_cuando_hace_click(x: number, y: number, evento_original) {

        }
      `;
        this.usar_receta(this.receta, this.dd);
      }

    }
  });

  _exports.default = _default;
});