define("pilas-engine/templates/components/pilas-propiedad/nombre", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mfxPzRS4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"pv1 flex texto relative\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"w-50\"],[9],[1,[29,\"etiqueta\",[[29,\"t\",[\"name\"],null]],null],false],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"role\",\"button\"],[11,\"class\",\"w-50 pb2\"],[9],[0,\"\\n    \"],[1,[29,\"pilas-boton-renombrar\",null,[[\"es_actor\",\"nombre\",\"cuando_cambia\"],[[25,[\"es_actor\"]],[25,[\"nombre\"]],[25,[\"cuando_cambia\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-propiedad/nombre.hbs"
    }
  });

  _exports.default = _default;
});