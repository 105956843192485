define("pilas-engine/services/recursos", ["exports", "pilas-engine/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    data: null,
    lista_de_actores: null,
    tarea: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      let data = {};
      let imagenes = yield this.obtenerImagenes("imagenes.json");
      let bloques = yield this.obtenerImagenes("bloques.json");
      let decoracion = yield this.obtenerImagenes("decoracion.json");
      let sprites_imagenes = this.convertir_en_tuplas_de_sprites(imagenes, "imagenes");
      let sprites_bloques = this.convertir_en_tuplas_de_sprites(bloques, "bloques");
      let sprites_decoracion = this.convertir_en_tuplas_de_sprites(decoracion, "decoracion");
      data.imagenesParaGrilla = sprites_imagenes.concat(sprites_bloques).concat(sprites_decoracion);
      this.set("data", data);
      return data;
    }).drop(),

    convertir_en_tuplas_de_sprites(lista, prefijo) {
      return lista.textures[0].frames.map(e => {
        return {
          nombre: `${prefijo}:${e.filename}`,
          sprite: prefijo + "-" + e.filename.replace("/", "-")
        };
      }).sortBy("nombre");
    },

    iniciar() {
      if (!this.data) {
        return this.tarea.perform();
      } else {
        return this.generar_respuesta_como_promesa_inmediata();
      }
    },

    obtenerImagenes(nombre_del_archivo) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", `${_environment.default.rootURL}${nombre_del_archivo}`);
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject(xhr.status);
          }
        };

        xhr.send();
      });
    },

    generar_respuesta_como_promesa_inmediata() {
      return new Ember.RSVP.Promise(success => {
        success(this.data);
      });
    }

  });

  _exports.default = _default;
});