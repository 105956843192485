define("pilas-engine/utils/recetas/actor/saltar-o-impulsar-cuando-hacen-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorSaltarOImpulsarCuandoHacenClick;

  function recetasActorSaltarOImpulsarCuandoHacenClick() {
    return {
      titulo: "Підсилення стрибків актора при кліку на ньому",
      icono: "receta_saltar",
      para: "actor",
      etiquetas: ["fisica", "impulsar", "saltar"],
      codigo: `
          cuando_hace_click(x: number, y: number, evento) {
            this.impulsar(0, 10);
            this.pilas.reproducir_sonido("salto-corto");
          }
      `
    };
  }
});