define("pilas-engine/components/pilas-input-filtro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this.element.querySelector("input").focus();
    }

  });

  _exports.default = _default;
});