define("pilas-engine/components/pilas-boton-miniatura", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      alternar() {
        this.toggleProperty("pulsado");

        if (this.alCambiarMaximizado) {
          let valor = this.get("pulsado");
          this.alCambiarMaximizado(valor);
        }
      }

    }
  });

  _exports.default = _default;
});