define("pilas-engine/templates/editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QXoTJlji",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"tareaCargarProyecto\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"pa2 gray verdana f6 tc\"],[9],[1,[29,\"t\",[\"app.loading\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[1,[29,\"pilas-editor\",null,[[\"proyecto\",\"pixelart\",\"mostrarEditor\",\"mostrarPropiedades\",\"escenaActual\",\"actorSeleccionado\",\"seleccion\",\"ultimaEscenaSeleccionada\",\"cuandoIntentaGuardar\",\"cuandoIntentaAbrir\",\"cuandoIntentaCrearUnProyecto\",\"mostrarModalCreacionDeActor\",\"mostrarInterprete\",\"permitir_modo_pausa\",\"zoom\",\"grilla\",\"modoZoom\",\"mostrarModalDeAnimaciones\",\"mostrarModalDeSonidos\",\"mostrarModalDeAyuda\",\"mostrarBloques\",\"modoVim\",\"oscuro\",\"pos\",\"fps\",\"minimapa\",\"fisica\",\"fisica_en_modo_ejecucion\",\"tamano\"],[[25,[\"tareaCargarProyecto\",\"last\",\"value\"]],[25,[\"pixelart\"]],[25,[\"mostrarEditor\"]],[25,[\"mostrarPropiedades\"]],[25,[\"escenaActual\"]],[25,[\"actorSeleccionado\"]],[25,[\"seleccion\"]],[25,[\"ultimaEscenaSeleccionada\"]],[29,\"action\",[[24,0,[]],\"al_guardar\"],null],[29,\"action\",[[24,0,[]],\"al_abrir\"],null],[29,\"action\",[[24,0,[]],\"al_crear_proyecto\"],null],[25,[\"mostrarModalCreacionDeActor\"]],[25,[\"mostrarInterprete\"]],[25,[\"permitir_modo_pausa\"]],[25,[\"zoom\"]],[25,[\"grilla\"]],[25,[\"modoZoom\"]],[25,[\"mostrarModalDeAnimaciones\"]],[25,[\"mostrarModalDeSonidos\"]],[25,[\"mostrarModalDeAyuda\"]],[25,[\"mostrarBloques\"]],[25,[\"modoVim\"]],[25,[\"oscuro\"]],[25,[\"pos\"]],[25,[\"fps\"]],[25,[\"minimapa\"]],[25,[\"fisica\"]],[25,[\"fisica_en_modo_ejecucion\"]],[25,[\"tamano\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/editor.hbs"
    }
  });

  _exports.default = _default;
});