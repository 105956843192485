define("pilas-engine/components/ui/importar-imagen", ["exports", "pilas-engine/utils/obtener-contenido-en-base-64"], function (_exports, _obtenerContenidoEnBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      seleccionar_imagen() {
        this.element.querySelector("input").click();
      },

      upload(event) {
        let promesas = [...event.target.files].map(archivo => {
          return (0, _obtenerContenidoEnBase.default)(archivo, "png");
        });
        Ember.RSVP.all(promesas).then(lista_de_archivos => {
          this.cuando_procesa_archivos(lista_de_archivos);
        });
      }

    }
  });

  _exports.default = _default;
});