define("pilas-engine/helpers/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.plus = plus;
  _exports.default = void 0;

  function plus(params
  /*, hash*/
  ) {
    return +params + 1;
  }

  var _default = Ember.Helper.helper(plus);

  _exports.default = _default;
});