define("pilas-engine/templates/components/pilas-acerca-de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NsremYxV",
    "block": "{\"symbols\":[\"parrafo\"],\"statements\":[[7,\"div\"],[11,\"class\",\"vh-100 w-100 dt\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-column vh-100\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"bb b--black-10 pa2\"],[9],[0,\"\\n      \"],[1,[23,\"pilas-boton-regresar\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"overflow-auto\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"center mw7 ph4 lato lh-copy pb6\"],[9],[0,\"\\n\\n        \"],[7,\"h2\"],[9],[1,[29,\"t\",[\"about.title\"],null],false],[10],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"parrafos\"]]],null,{\"statements\":[[0,\"          \"],[7,\"p\"],[9],[1,[24,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n          \"],[7,\"em\"],[9],[1,[29,\"t\",[\"about.Ukrainian.translation\"],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"about.end\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"ul\"],[9],[0,\"\\n          \"],[7,\"li\"],[9],[1,[29,\"t\",[\"about.web.link\"],null],false],[0,\": \"],[4,\"pilas-link\",null,[[\"url\"],[\"http://pilas-engine.com.ar\"]],{\"statements\":[[0,\"pilas-engine.com.ar\"]],\"parameters\":[]},null],[10],[0,\"\\n          \"],[7,\"li\"],[9],[1,[29,\"t\",[\"about.forum.link\"],null],false],[0,\": \"],[4,\"pilas-link\",null,[[\"url\"],[\"http://foro.pilas-engine.com.ar\"]],{\"statements\":[[0,\"foro.pilas-engine.com.ar\"]],\"parameters\":[]},null],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[11,\"class\",\"tc gray pt3\"],[9],[0,\"\\n          © Hugo Ruscitti\\n        \"],[10],[0,\"\\n\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-acerca-de.hbs"
    }
  });

  _exports.default = _default;
});