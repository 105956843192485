define("pilas-engine/utils/recetas/actor/reproducir-sonido-al-comenzar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorReproducirSonidoAlComenzar;

  function recetasActorReproducirSonidoAlComenzar() {
    return {
      titulo: "Відтворення звуку при запуску",
      icono: "receta_sonido",
      para: "actor",
      etiquetas: ["sonido"],
      codigo: `
          iniciar() {
            this.reproducir_sonido("moneda");

            // Otras opciones:

            //this.reproducir_sonido("laser");
            //this.reproducir_sonido("salto-largo");
            //this.reproducir_sonido("salto-corto");
          }
      `
    };
  }
});