define("pilas-engine/components/pilas-ordenable-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    sortable: null,

    didInsertElement() {
      this.instanciar_sortable();
    },

    didDestroyElement() {
      this.eliminar_sortable();
    },

    instanciar_sortable() {
      let elemento = document.getElementById(this.identificador);
      this.set("sortable", Sortable.create(elemento, {
        filter: ".no-mover",
        handle: ".my-handle",
        onEnd: evento => {
          this.eliminar_sortable();
          let orden = this.obtener_orden(evento);
          let dragItem = evento.item;
          dragItem.parentNode.removeChild(dragItem);
          this.cuando_cambia_orden(orden);
          Ember.run.later(this, () => {
            this.instanciar_sortable();
          }, 5);
        }
      }));
    },

    obtener_orden(evento) {
      let array_children = Array.from(evento.target.children);
      let orden = array_children.map(e => e.getAttribute("data-index"));
      return orden.filter(e => e).map(e => parseInt(e, 10));
    },

    eliminar_sortable() {
      if (this.sortable) {
        this.sortable.destroy();
      }
    }

  });

  _exports.default = _default;
});