define("pilas-engine/utils/recetas/actor/cuando-colisiona-eliminar-al-otro-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorCuandoColisionaEliminarAlOtroActor;

  function recetasActorCuandoColisionaEliminarAlOtroActor() {
    return {
      titulo: "Коли цей актор зіткнеться з іншим, видалити іншого актора",
      icono: "receta_colision",
      para: "actor",
      etiquetas: ["colisión", "toca", "golpea"],
      codigo: `
          // Викликається, якщо двоє акторів стикаються динамічними формами
          // або один із динамічною формою та один із не динамічною формою.
          cuando_comienza_una_colision(otro_actor: Actor) {
            otro_actor.eliminar();
          }
      `
    };
  }
});