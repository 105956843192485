define("pilas-engine/templates/components/pilas-panel-de-escenas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E1+vvaVI",
    "block": "{\"symbols\":[\"escena\"],\"statements\":[[7,\"ul\"],[11,\"id\",\"pilas-panel-de-escenas\"],[12,\"class\",[30,[\"verdana f6 unselectable pa0 flex1 overflow-y-auto ba borde mt2 mb2 \",[29,\"if\",[[25,[\"desactivado\"]],\"o-40 disabled\"],null]]]],[9],[0,\"\\n\\n  \"],[7,\"li\"],[11,\"role\",\"button\"],[12,\"class\",[30,[\"pointer texto truncate pa2 \",[29,\"if\",[[29,\"eq\",[[25,[\"seleccion\"]],0],null],\"bg-black-20\",\"hover-bg-black-10\"],null]]]],[9],[0,\"\\n    ◆ \"],[1,[29,\"t\",[\"panel.project\"],null],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],[25,[\"cuandoSelecciona\"]],\"proyecto\"]],[10],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"escenas\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[29,\"pilas-panel-de-escenas/item-escena\",null,[[\"cuandoSelecciona\",\"ultimaEscenaSeleccionada\",\"escena\",\"seleccion\",\"cuando_intenta_eliminar\"],[[25,[\"cuandoSelecciona\"]],[25,[\"ultimaEscenaSeleccionada\"]],[24,1,[]],[25,[\"seleccion\"]],[25,[\"cuando_intenta_eliminar\"]]]]],false],[0,\"\\n\\n\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"ultimaEscenaSeleccionada\"]],[24,1,[\"id\"]]],null]],null,{\"statements\":[[0,\"\\n      \"],[1,[29,\"pilas-panel-de-escenas/contenido-de-la-escena\",null,[[\"escena\",\"seleccion\",\"cuandoSelecciona\",\"escenas\",\"ultimaEscenaSeleccionada\",\"cuando_intenta_mover_a_escena_nueva\",\"cuando_intenta_mover_a_una_escena\"],[[24,1,[]],[25,[\"seleccion\"]],[25,[\"cuandoSelecciona\"]],[25,[\"escenas\"]],[25,[\"ultimaEscenaSeleccionada\"]],[25,[\"cuando_intenta_mover_a_escena_nueva\"]],[25,[\"cuando_intenta_mover_a_una_escena\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"orange texto ph3 pt2\"],[9],[1,[29,\"t\",[\"panel.no.scenes\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-panel-de-escenas.hbs"
    }
  });

  _exports.default = _default;
});