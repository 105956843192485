define("pilas-engine/helpers/etiqueta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.etiqueta = etiqueta;
  _exports.default = void 0;

  function etiqueta(params) {
    if (params[0]) {
      var words = params[0].split(" ");
      var results = [];

      for (var i = 0; i < words.length; i++) {
        var letter = words[i].charAt(0).toUpperCase();
        results.push(letter + words[i].slice(1));
      }

      return results.join(" ").replace(/_/g, " ");
    }
  }

  var _default = Ember.Helper.helper(etiqueta);

  _exports.default = _default;
});