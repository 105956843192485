define("pilas-engine/templates/components/pilas-celda-de-imagen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SeG5KjyF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"test-celda-imagen\",\"\"],[12,\"class\",[30,[\"\\n    \",[29,\"if\",[[25,[\"debeMostrar\"]],\"dib\",\"dn\"],null],\"\\n    w3\\n    verdana f6\\n    black\\n    unselectable\\n    v-top\\n    mv1\\n    texto\\n    hide-child\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-column h-100 w-100\"],[9],[0,\"\\n\\n    \"],[15,1],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"flex pa2 h3 items-center justify-center w-100 hover-bg-black-10 pulsable bg-animate\"],[11,\"role\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"imagen\",\"contenido\"]]],null,{\"statements\":[[0,\"        \"],[7,\"img\"],[12,\"src\",[25,[\"imagen\",\"contenido\"]]],[11,\"alt\",\"\"],[11,\"class\",\"object-fit-contain\"],[12,\"width\",48],[12,\"height\",48],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[30,[\"sprite-grilla-de-imagenes \",[25,[\"imagen\",\"sprite\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],\"cuando_selecciona_imagen\",[25,[\"imagen\"]]]],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"child f7 tc mt2 h1 truncate\"],[9],[0,\"\\n      \"],[1,[29,\"nombre-de-imagen\",[[25,[\"imagen\",\"nombre\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-celda-de-imagen.hbs"
    }
  });

  _exports.default = _default;
});