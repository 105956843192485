define("pilas-engine/templates/components/pilas-mensaje-exportador", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ut4DppD5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"mensaje\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"mensaje\",\"link\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"f6 pb1\"],[9],[1,[25,[\"mensaje\",\"mensaje\"]],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"f6 pb1 flex items-center pl3 pt2\"],[9],[4,\"pilas-link\",null,[[\"class\",\"url\"],[\"link texto pointer\",[25,[\"mensaje\",\"link\"]]]],{\"statements\":[[1,[25,[\"mensaje\",\"link\"]],false]],\"parameters\":[]},null],[0,\" \"],[7,\"span\"],[11,\"class\",\"dib w1\"],[9],[10],[1,[29,\"pilas-boton-copiar\",null,[[\"miniatura\",\"texto\"],[true,[25,[\"mensaje\",\"link\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"mensaje\",\"codigo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"f6 pb1 flex items-center pl3\"],[9],[0,\"\\n      \"],[7,\"pre\"],[9],[1,[25,[\"mensaje\",\"codigo\"]],false],[10],[0,\"      \"],[7,\"span\"],[11,\"class\",\"dib w1\"],[9],[10],[0,\"\\n      \"],[1,[29,\"pilas-boton-copiar\",null,[[\"miniatura\",\"texto\"],[true,[25,[\"mensaje\",\"codigoCompleto\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"mensaje\",\"tipo\"]],\"captura\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[25,[\"mensaje\",\"data\"]]],[11,\"alt\",\"captura de pantalla\"],[11,\"style\",\"width: 180px; margin-top: 10px; margin-bottom: 10px;\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"f6 pb1\"],[9],[1,[23,\"mensaje\"],false],[10],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n     \\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-mensaje-exportador.hbs"
    }
  });

  _exports.default = _default;
});