define("pilas-engine/templates/explorar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hkfREWBO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"vh-100 flex flex-column\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"bb b--black-10 pa2\"],[9],[0,\"\\n    \"],[1,[23,\"pilas-boton-regresar\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"overflow-y-scroll\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"center mw8 ph4 lato lh-copy pb6\"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[1,[29,\"t\",[\"home.explore\"],null],false],[10],[0,\"\\n\\n      \"],[7,\"p\"],[9],[1,[29,\"t\",[\"explore.description\"],null],false],[10],[0,\"\\n\\n      \"],[1,[23,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/explorar.hbs"
    }
  });

  _exports.default = _default;
});