define("pilas-engine/components/pilas-version", ["exports", "pilas-engine/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      version
    },
    environment
  } = _environment.default;

  var _default = Ember.Component.extend({
    consultando: true,
    version_en_el_servidor: null,
    actualizanda: true,
    version: version,
    error: false,
    version_simplificada: Ember.computed("version", function () {
      return this.version.replace("v", "").split("+")[0];
    }),
    enDesarrollo: Ember.computed("version", function () {
      return /app-dev/.test(window.location.href);
    }),

    didInsertElement() {
      this.consultar_ultima_version_publicada.perform();
    },

    consultar_ultima_version_publicada: (0, _emberConcurrency.task)(function* () {
      const base = "https://api.github.com/repos";
      const url = `${base}/pilas-engine/pilas-engine/releases/latest`;
      yield (0, _emberConcurrency.timeout)(1000);

      if (environment === "test") {
        this.set("error", "Omitiendo consultar versión en los tests.");
        this.set("consultando", false);
        return;
      }

      try {
        let data = yield this.obtener_datos_desde_github(url);
        this.set("version_en_el_servidor", data.tag_name);
        this.set("actualizada", "v" + this.version >= data.tag_name);
      } catch (e) {
        this.set("error", "No se puede consultar la última versión.");
      } finally {
        this.set("consultando", false);
      }
    }),

    obtener_datos_desde_github(url) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject(xhr.status);
          }
        };

        xhr.send();
      });
    }

  });

  _exports.default = _default;
});