define("pilas-engine/templates/components/pilas-panel-de-escenas/item-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZFQojYoe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"dib br-100 \",[29,\"if\",[[25,[\"actor\",\"color\"]],[25,[\"actor\",\"color\"]],\"bg-silver\"],null],\" icono-item-de-actor\"]]],[9],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"flex1 pl2\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"actor\",\"activo\"]],false],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"o-50\"],[9],[1,[25,[\"actor\",\"nombre\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,[\"actor\",\"nombre\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"dib fr f7\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"seleccion\"]],[25,[\"actor\",\"id\"]]],null]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"actor\",\"activo\"]],false],null]],null,{\"statements\":[[0,\"      \"],[1,[23,\"pilas-indicador-inactivo\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[23,\"cuando_intenta_duplicar\"],false],[0,\"\\n\\n    \"],[1,[29,\"pilas-boton-menu-actor\",null,[[\"escenas\",\"actor_id\",\"escena_id\",\"accionDefinirColor\",\"accionClonar\",\"accionClonarX5\",\"accionEliminar\",\"accionCuandoIntentaMoverAEscenaNueva\",\"accionCuandoIntentaMoverAUnaEscena\"],[[25,[\"escenas\"]],[25,[\"actor\",\"id\"]],[25,[\"ultimaEscenaSeleccionada\"]],[29,\"action\",[[24,0,[]],\"cambiar_color\",[25,[\"actor\",\"id\"]]],null],[29,\"action\",[[24,0,[]],\"cuando_intenta_duplicar\",[25,[\"actor\",\"id\"]]],null],[29,\"action\",[[24,0,[]],\"cuando_intenta_duplicar_x5\",[25,[\"actor\",\"id\"]]],null],[29,\"action\",[[24,0,[]],\"cuando_intenta_eliminar\",[25,[\"actor\",\"id\"]]],null],[29,\"action\",[[24,0,[]],\"cuando_intenta_mover_a_una_escena_nueva\"],null],[29,\"action\",[[24,0,[]],\"cuando_intenta_mover_a_otra_escena\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-panel-de-escenas/item-actor.hbs"
    }
  });

  _exports.default = _default;
});