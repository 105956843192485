define("pilas-engine/components/pilas-mover-actor-de-escena", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    modalVisible: false,
    actions: {
      ocultar() {
        this.set("modalVisible", false);
      },

      mostrar() {
        this.set("modalVisible", true);
      },

      ocultarEjecutandoAccion() {
        this.send("ocultar");
        this.accion();
      }

    }
  });

  _exports.default = _default;
});