define("pilas-engine/controllers/acercade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    electron: Ember.inject.service(),
    actions: {
      abrir_el_sitio_de_pilas() {
        this.electron.abrir_en_un_navegador("http://pilas-engine.com.ar");
      },

      abrir_el_foro_de_pilas() {
        this.electron.abrir_en_un_navegador("http://foro.pilas-engine.com.ar/");
      },

      abrirInspector() {
        this.electron.abrirInspector();
      }

    }
  });

  _exports.default = _default;
});