define("pilas-engine/utils/modifier-keys", ["exports", "ember-key-manager/utils/modifier-keys"], function (_exports, _modifierKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modifierKeys.default;
    }
  });
});