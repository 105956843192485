define("pilas-engine/templates/components/pilas-boton-para-colapsar-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ghMb2Rr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"data-test\",[23,\"dataTest\"]],[11,\"class\",\"dib pointer o-50 hover-o-100\"],[11,\"role\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"variable\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[\"panel-abierto\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"pilas-icono\",null,[[\"icono\"],[\"panel-cerrado\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[24,0,[]],\"alternar\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-para-colapsar-panel.hbs"
    }
  });

  _exports.default = _default;
});