define("pilas-engine/templates/components/pilas-solapa-de-ayuda", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qSww3NQs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[29,\"if\",[[25,[\"seleccionada\"]],\"solapa-de-ayuda-seleccionada\",\"solapa-de-ayuda\"],null]],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[1,[23,\"texto\"],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[25,[\"accion\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-solapa-de-ayuda.hbs"
    }
  });

  _exports.default = _default;
});