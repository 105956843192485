define("pilas-engine/templates/application-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BLnTz5V4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"verdana f6 tc pa2 orange\"],[9],[1,[29,\"t\",[\"error.title\"],null],false],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"verdana f6 tc pa2 orange\"],[9],[1,[29,\"t\",[\"error.detail\"],null],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"tc orange\"],[9],[0,\"\\n  \"],[1,[25,[\"model\",\"responseText\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/application-error.hbs"
    }
  });

  _exports.default = _default;
});