define("pilas-engine/templates/components/pilas-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Re4l1gF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\"],[12,\"class\",[30,[\"\\n    bn\\n    animar-width\\n    center\\n    mw-100\\n    \",[23,\"clasesDeAcuerdoAlModoZoom\"],\"\\n  \"]]],[12,\"src\",[30,[[23,\"rootURL\"],\"pilas-canvas.html\"]]],[12,\"width\",[23,\"widthParaElModoZoomEnviado\"]],[12,\"height\",[23,\"heightParaElModoZoomEnviado\"]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-canvas.hbs"
    }
  });

  _exports.default = _default;
});