define("pilas-engine/templates/components/pilas-propiedad/nombre-de-sensor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TU6Pt3cO",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[15,2],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"texto\"],[11,\"role\",\"button\"],[9],[0,\"\\n\\n\"],[4,\"basic-dropdown\",null,null,{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"trigger\"]],\"expected `dd.trigger` to be a contextual component but found a string. Did you mean `(component dd.trigger)`? ('pilas-engine/templates/components/pilas-propiedad/nombre-de-sensor.hbs' @ L5:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"br2 dib input mv2 pa1 w-input-nombre-de-sensor no-outline\"],[9],[0,\"\\n        \"],[1,[25,[\"sensor\",\"nombre\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"content\"]],\"expected `dd.content` to be a contextual component but found a string. Did you mean `(component dd.content)`? ('pilas-engine/templates/components/pilas-propiedad/nombre-de-sensor.hbs' @ L11:C7) \"],null]],[[\"class\"],[\"slide-fade bg-fondo ba borde shadow-4 pa2\"]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"texto\"],[9],[0,\"\\n        \"],[1,[29,\"pilas-input-nombre-validado-de-sensor\",null,[[\"valor_inicial\",\"sensores\",\"cuandoCambia\",\"dd\"],[[25,[\"sensor\",\"nombre\"]],[25,[\"sensores\"]],[25,[\"cuandoCambia\"]],[24,1,[]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-propiedad/nombre-de-sensor.hbs"
    }
  });

  _exports.default = _default;
});