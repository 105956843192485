define("pilas-engine/templates/components/pilas-boton-configuracion-del-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k5q6pNjq",
    "block": "{\"symbols\":[\"dd\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dib\"],[9],[0,\"\\n\\n\"],[4,\"basic-dropdown\",null,null,{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"trigger\"]],\"expected `dd.trigger` to be a contextual component but found a string. Did you mean `(component dd.trigger)`? ('pilas-engine/templates/components/pilas-boton-configuracion-del-canvas.hbs' @ L4:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"pointer f7 dib boton-miniatura\"],[11,\"role\",\"button\"],[9],[0,\"\\n        \"],[1,[29,\"pilas-mini-icono\",null,[[\"icono\"],[\"configuracion\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"content\"]],\"expected `dd.content` to be a contextual component but found a string. Did you mean `(component dd.content)`? ('pilas-engine/templates/components/pilas-boton-configuracion-del-canvas.hbs' @ L10:C7) \"],null]],[[\"class\"],[\"slide-fade bg-fondo ba borde shadow-4 pa2 f7\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n        \"],[1,[29,\"pilas-interruptor\",null,[[\"dataTest\",\"variable\",\"texto\"],[\"boton-modo-oscuro\",[25,[\"oscuro\"]],[29,\"t\",[\"settings.dark.mode\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n        \"],[1,[29,\"pilas-interruptor\",null,[[\"dataTest\",\"variable\",\"texto\"],[\"boton-modo-oscuro\",[25,[\"permitir_modo_pausa\"]],[29,\"t\",[\"settings.allow.pause\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-boton-configuracion-del-canvas.hbs"
    }
  });

  _exports.default = _default;
});