define("pilas-engine/templates/components/pilas-propiedad/combo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zUXwaiac",
    "block": "{\"symbols\":[\"opcion\"],\"statements\":[[7,\"div\"],[11,\"class\",\"flex items-center\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-50\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"dib texto\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"titulo\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"etiqueta\",[[25,[\"titulo\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[29,\"etiqueta\",[[25,[\"propiedad\",\"propiedad\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-50\"],[9],[0,\"\\n\\n    \"],[7,\"select\"],[11,\"class\",\"w-100 select\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"al_seleccionar\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"opciones\"]]],null,{\"statements\":[[0,\"        \"],[7,\"option\"],[12,\"value\",[24,1,[\"valor\"]]],[12,\"selected\",[29,\"eq\",[[25,[\"valor_actual\"]],[24,1,[\"valor\"]]],null]],[9],[1,[24,1,[\"texto\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-propiedad/combo.hbs"
    }
  });

  _exports.default = _default;
});