define("pilas-engine/templates/components/pilas-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kEOwAxca",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"visible\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[\"modal\",[25,[\"cancelarWormhole\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[\"flex \",[29,\"if\",[[25,[\"fixed\"]],\"fixed\",\"absolute\"],null],\" absolute--fill default-cursor verdana\"]]],[12,\"id\",[23,\"id\"]],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"role\",\"button\"],[11,\"class\",\"bg-black-50 z-1 animacion-aparecer absolute absolute--fill\"],[9],[3,\"action\",[[24,0,[]],\"ocultar\"]],[10],[0,\"\\n\\n      \"],[7,\"div\"],[12,\"class\",[30,[\"flex texto borde ba shadow-3 flex-column flex-auto ma4 bg-fondo z-2 \",[29,\"if\",[[25,[\"medio\"]],\"dialogo-medio\"],null],\" \",[29,\"if\",[[25,[\"reducido\"]],\"h4-5 margin-left-auto margin-right-auto mw6\"],null]]]],[9],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"flex\"],[9],[0,\"\\n          \"],[7,\"h1\"],[11,\"class\",\"f4 pl3 flex1\"],[11,\"id\",\"titulo\"],[9],[1,[23,\"titulo\"],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"puede_cerrar\"]]],null,{\"statements\":[[0,\"            \"],[7,\"button\"],[11,\"class\",\"pointer w2 h2 ma1 bn bg-fondo texto f3 pr2\"],[11,\"id\",\"cerrar\"],[9],[0,\"\\n              ×\\n            \"],[3,\"action\",[[24,0,[]],\"ocultar\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"flex1 pa2 overflow-auto flex flex-column\"],[9],[0,\"\\n          \"],[15,1],[0,\"\\n        \"],[10],[0,\"\\n\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-modal.hbs"
    }
  });

  _exports.default = _default;
});