define("pilas-engine/components/pilas-gestor-de-sonidos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    proyecto: null,
    recursos: Ember.inject.service(),
    servicioProyecto: Ember.inject.service("proyecto"),
    iniciando: true,
    id: 1,

    didInsertElement() {
      this.cargar_sonidos();
      let primer_sonido = this.sonidos.firstObject;
      this.set("sonido_seleccionado", primer_sonido);
    },

    cargar_sonidos() {
      let sonidos = this.proyecto.sonidos.map(s => Ember.Object.create(s));
      this.set("sonidos", sonidos);
    },

    actions: {
      reproducir(sonido) {
        if (!this.iniciando) {
          this.set("id", Math.random());
          sonido.set("reproduciendo", true);
          this.set("sonido_seleccionado", sonido);
        }
      },

      cuando_termina_de_reproducir(nombre) {
        this.sonidos.findBy("nombre", nombre).set("reproduciendo", false);
      },

      incorporar_sonidos_al_proyecto(lista_de_sonidos) {
        this.servicioProyecto.incorporar_sonidos_al_proyecto(lista_de_sonidos);
        this.set("iniciando", true);
        this.cargar_sonidos();
      }

    }
  });

  _exports.default = _default;
});