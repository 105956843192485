define("pilas-engine/templates/components/pilas-previsualizacion-de-animacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ycZXaETU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[4,\"pilas-reiniciador\",null,null,{\"statements\":[[0,\"  \"],[1,[29,\"pilas-canvas\",null,[[\"ancho\",\"nombre_del_contexto\",\"alto\",\"recursos\",\"proyecto\",\"modoZoom\",\"pixelart\",\"cuando_termina_de_cargar\",\"cuando_termina_de_iniciar_ejecucion\"],[[25,[\"proyecto\",\"ancho\"]],[25,[\"nombre_del_contexto\"]],[25,[\"proyecto\",\"alto\"]],[25,[\"recursos\"]],[25,[\"proyecto\"]],1,[25,[\"pixelart\"]],[29,\"action\",[[24,0,[]],\"cuando_termina_de_cargar\"],null],[29,\"action\",[[24,0,[]],\"cuando_termina_de_iniciar_ejecucion\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-previsualizacion-de-animacion.hbs"
    }
  });

  _exports.default = _default;
});