define("pilas-engine/components/panel-ocultable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["variable:panel-ocultable__invisible"],
    classNames: ["pilas-phaser-experimental__panel"]
  });

  _exports.default = _default;
});