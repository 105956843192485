define("pilas-engine/templates/components/pilas-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UXR08sEy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"recursos\",\"data\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"style\",\"width: 700px; height: 700px; display: flex; justify-content: center; margin: auto;\"],[9],[0,\"\\n    \"],[1,[29,\"pilas-canvas\",null,[[\"nombre_del_contexto\",\"ancho\",\"alto\",\"proyecto\",\"pixelart\"],[\"pilas-test\",350,350,[25,[\"proyecto\"]],false]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-test.hbs"
    }
  });

  _exports.default = _default;
});