define("pilas-engine/components/pilas-interruptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    resaltar: true,
    debe_resaltar_el_fondo: Ember.computed('resaltar', 'variable', function () {
      if (this.resaltar) {
        return this.variable;
      }
    }),
    icono_on: Ember.computed('icono', function () {
      return `${this.icono}-on`;
    }),
    icono_off: Ember.computed('icono', function () {
      return `${this.icono}-off`;
    }),
    actions: {
      alternar() {
        // Solo si se especifica una acción, intenta respetar
        // "data down, actions ups" sin tocar por si mismo el argumento que
        // recibe.
        if (this.cuandoCambia) {
          this.cuandoCambia(!this.variable);
        } else {
          this.toggleProperty("variable");
        }
      }

    }
  });

  _exports.default = _default;
});