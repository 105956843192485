define("pilas-engine/templates/components/pilas-input-filtro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HBnpLhiA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"],[7,\"input\"],[11,\"spellcheck\",\"false\"],[12,\"value\",[23,\"filtro\"]],[11,\"placeholder\",\"Пошук\"],[11,\"class\",\"pa2 input\"],[12,\"oninput\",[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"filtro\"]]],null]],[[\"value\"],[\"target.value\"]]]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "pilas-engine/templates/components/pilas-input-filtro.hbs"
    }
  });

  _exports.default = _default;
});