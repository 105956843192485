define("pilas-engine/components/pilas-celda-de-actor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    debeOcultarPorFiltro: Ember.computed("filtro", function () {
      return !this.actor.nombre.includes(this.filtro);
    }),
    nombreSeparadoEnPalabras: Ember.computed("actor.nombre", function () {
      return this.actor.nombre.replace(/_/g, " ");
    })
  });

  _exports.default = _default;
});