define("pilas-engine/utils/copiar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = copiar;

  function copiar(dato) {
    // caso especial: se da cuando se quiere asignar fondo a un
    // actor de texto por primera vez.
    if (dato === undefined) {
      return undefined;
    }

    return JSON.parse(JSON.stringify(dato));
  }
});