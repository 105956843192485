define("pilas-engine/helpers/helper-animacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.helperAnimacion = helperAnimacion;
  _exports.default = void 0;

  function helperAnimacion(params) {
    let nombre = params[0];
    return `this.animacion = "${nombre}";`;
  }

  var _default = Ember.Helper.helper(helperAnimacion);

  _exports.default = _default;
});