define("pilas-engine/utils/convertir-proyecto-ember-en-diccionario-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertirProyectoEmberEnDiccionarioSimple;

  function convertirProyectoEmberEnDiccionarioSimple(proyecto) {
    return JSON.parse(JSON.stringify(proyecto));
  }
});