define("pilas-engine/utils/recetas/actor/cuando-colisiona-explotar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasActorCuandoColisionaEliminarAlOtroActor;

  function recetasActorCuandoColisionaEliminarAlOtroActor() {
    return {
      titulo: "Вибухнути при зіткнення",
      icono: "receta_colision",
      para: "actor",
      etiquetas: ["colisión", "toca", "golpea", "explosion"],
      codigo: `
          // Викликається при зіткненні з датчиком. Якщо ви хочете зробити
          // зіткнення між формами, а не датчиками, використовуйте функцію
          // "when_a_collision_starts".
          cuando_colisiona(actor: Actor) {
            this.eliminar();

            let explosion = this.pilas.actores.explosion();
            explosion.x = this.x;
            explosion.y = this.y;
          }
      `
    };
  }
});