define("pilas-engine/controllers/proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["sin_cabecera"],
    sin_cabecera: false
  });

  _exports.default = _default;
});