define("pilas-engine/utils/recetas/escena/observar-el-movimiento-del-mouse-o-cursor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recetasEscenaObservarElMovimientoDelMouseOCursor;

  function recetasEscenaObservarElMovimientoDelMouseOCursor() {
    return {
      titulo: "Показувати координати вказівника миші",
      icono: "receta_mueve",
      para: "escena",
      etiquetas: ["mouse", "cursor", "mover", "observar"],
      codigo: `
        cuando_mueve(x: number, y: number, evento: any) {
          this.pilas.observar("Координата x", x);
          this.pilas.observar("Координата y", y);
        }
      `
    };
  }
});